import React, { Fragment } from "react";

import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl'
import TextField from "@mui/material/TextField";

export default function CajaTexto({ ...props }) {

    const controlaTexto = event => {
        let valor = event.target.value
        if (props.tipo === "number") {
            if (isNaN(Number(valor))) {
                valor = 0
            } else {
                //valor = Number(valor)
                // impedir ceros por delatante 001... //
                if (valor.substr(0, 1) === '0' && valor.substr(1, 1) !== '.') {
                    // (+valor):Convierte en Numero. (+""): Convierte en Texto. //
                    valor = +valor + ""
                }
            }
        }
        props.accion(valor)
    }

    let color = props.color !== undefined ? props.color : 'blue'

    // Objeto con la parametrización para la visualización. //
    const InputCSS = {
        width: props.ancho,
        // to override the styles of inner elements, 
        // you have to use the & selector followed by the class name.
        "& .MuiInputBase-root": {
            marginLeft: "5px",
            height: 20, // 25
            color: color,
            fontsize: 12, // 14
            fontWeight: 700,
        },
        "& .MuiInputBase-input": {
            padding: '4px 6px 4px 6px',
            textAlign: (props.tipo) === "number" ? 'right' : 'left',
        },
        // Quitar las flechas de los Números. //
        /*
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        */
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
        "& input[type=number]": {
            MozAppearance: "textfield",
        },
        //'&.MuiInputBase-root:focus': {
        //},
    }


    return (
        <Fragment>
            <CssBaseline />
            <FormControl>
                <TextField
                    id={"Txt_" + props.id}
                    label=""
                    autoFocus={props.foco}
                    type={props.tipo} //"text" // siempre texto
                    sx={InputCSS}
                    disabled={props.disabled}
                    value={props.valor}
                    // Si es number No permitir "." punto, "-" menos, "Ee" cientifica. //
                    onKeyDown={(evt) =>
                       props.tipo === "number" && ".-Ee".includes(evt.key) && evt.preventDefault()
                    }
                    onChange={controlaTexto}
                />
            </FormControl>
        </Fragment>
    )
}