import React, { useState } from "react";
import { useStore } from 'react-create-use-store';
import { withTranslation } from 'react-i18next'
import { useNavigate, /*Redirect*/ } from "react-router-dom"

import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../ThemeGeroa.js'
import store from './../adderStore.js'

import { signin } from './../apis/api-auth.js'
import { listaMercadosProvCoor } from "../apis/api-mercado.js";
import auth from './../apis/auth-helper.js'

//import Cabecera from './../core/cabecera'

const Identificarse = ({ t, ...props }) => {

    const { state, actions } = useStore(store);

    const [values, setValues] = useState({ usuario: '', password: '' })
    const [openDialog, setOpenDialog] = useState(false);
    const [error, setError] = useState(false)

    const navigate = useNavigate();

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    // Para mostrar/ocultar contraseña //
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show);



    // Por si hay error
    const muestraError = (
        error ?
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle variant="h2" id="alert-dialog-title">
                    {t("General.Advertencia")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText variant="h4" id="alert-dialog-description">
                        {error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDialog} autoFocus>
                        {t("Aceptar")}
                    </Button>
                </DialogActions>
            </Dialog>
            :
            <div />
    )



    // Valida únicamente la sintaxis de los 2 campos (email y password)
    async function validate() {
        let err = ""
        if (values.usuario.length === 0) {
            err = t('El Usuario es obligatorio')
        } else if (values.password.length === 0) {
            err = t('El password es obligatorio')
        } else if (values.password.length < 4) {
            err = t('Este password parece demasiado corto')
        } else {
            // Busca en la base de datos
            await signin({ u: values.usuario, p: values.password }).then((data) => {
                if (data === undefined || data === "Usuario o Password incorrectos") {
                    err = t('El Email o el password no son correctos')
                }
                else {
                    auth.authenticate(data, async () => {
                        // Cojer los datos necesarios del Usuario
                        let email = data.user.email
                        let productor = data.user.coordinador !== 1 ? true : false
                        let proveedor = data.user.proveedor
                        //
                        actions.cambiarEmailUsuario(email)
                        actions.cambiarEsProductor(productor)
                        actions.cambiarCodProveedor(proveedor)
                        //
                        // Coger Otros Datos Necesarios: Mercados del Productor
                        await listaMercadosProvCoor({ esProductor: productor ? "1" : "0", proveedor: proveedor }).then((dataM) => {
                            if (dataM.error) {
                                console.log(dataM.error)
                            }
                            else {
                                actions.cambiarMercadosProveedor(dataM)
                            }
                        })

                    })
                    err = ""
                    // Salta
                    return navigate(state.saltarA === '' ? '/' : state.saltarA)
                }
            })
        }
        if (err !== "") {
            setError(err)
            setOpenDialog(true)
        }
        return true
    }


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ width: 5 / 12 }}>
                {muestraError}
                <Typography style={{ fontSize: '32px', color: '#00233D', fontWeight: 600 }}>
                    {t('Identificación')}
                </Typography>
                <TextField sx={{ mt: 1 }}
                    required
                    variant="standard"
                    fullWidth
                    label={t("Usuario")}
                    value={values.usuario}
                    onChange={handleChange('usuario')}
                />
                <TextField sx={{ mt: 1 }}
                    //type="password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    variant="standard"
                    fullWidth
                    label={t("Password")}
                    value={values.password}
                    onChange={handleChange('password')}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="cambia visibilidad de la contraseña"
                                onClick={handleClickShowPassword}
                                //onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'right' }}>
                    <Button variant="contained" onClick={() => { validate() }}>
                        {t("Aceptar")}
                    </Button>
                </Box>
            </Container>
        </ThemeProvider >
    );
}

export default withTranslation()(Identificarse)