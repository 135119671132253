import React, { Fragment, useState } from 'react';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';

//import config from './../config/config.js'
const defaultImgProducto = require('./../assets/images/defectos/producto_0000.png')

export default function AmpliaImagen({ ...props }) {

    const tamPeq = "80"
    const tamGra = "250"
    //const fotoPeq = config.rutaFotosProductos + props.prod + '.jpg' + '?f1=' + (new Date().getTime())
    //const fotoGra = config.rutaFotosProductos + props.prod + '.jpg' + '?f2=' + (new Date().getTime())
    const fotoPeq = props.foto
    const fotoGra = props.foto

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = event => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Fragment>
            
            <Grid sx={{ marginLeft: '8px', marginRight: '20px', mt: -2.5}}>
               <img
                    //id={'Peq' + props.prod + (new Date().getTime())}
                    width={tamPeq}
                    //height={tamPeq}
                    src={fotoPeq}
                    onError={(event) => event.target.setAttribute("src", defaultImgProducto)}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    alt="Producto"
                />
                <Popover
                    sx={{ pointerEvents: 'none' }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <img
                        //id={'Gra' + props.prod + (new Date().getTime())}
                        width={tamGra}
                        //height={tamGra}
                        src={fotoGra}
                        onError={(event) => event.target.setAttribute("src", defaultImgProducto)}
                        alt="Imagen ampliada"
                    />
                </Popover>
            </Grid>
        </Fragment>
    )
}