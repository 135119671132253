import React, { Fragment, useState } from "react";
import { withTranslation } from 'react-i18next'

import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
// Castellano //
import "dayjs/locale/es";
// Euskera //
import "dayjs/locale/eu";

import { useStore } from 'react-create-use-store';
import store from './../adderStore.js'


const BotonFecha = ({ t, ...props }) => {

  // Ajusta cosas que no llegan
  let margenIzquierdo = props.margenI ? props.margenI : 0

  const { state, /*actions*/ } = useStore(store);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleAbandonar = () => {
    setOpen(false);
  }

  // La fecha llega como DD-MM-AAAA //
  let a = props.fecha.substr(6, 4)
  // Los meses van de 0 a 11. Enero es 0 //
  let m = props.fecha.substr(3, 2) - 1
  let d = props.fecha.substr(0, 2)
  let fec = new Date(a, m, d)
  const [value, setValue] = useState(dayjs(fec));

  const handleGuardar = () => {
    props.actualizadorFecha(new Date(value.$y, value.$M, value.$D))
    setOpen(false);
  }


  // Añadir component={'span'} en DialogContentText para quitar el error en el navegador: 
  // Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>
  //<DialogContentText id="alert-dialog-description" component={'span'}>
  return (
    <Fragment>
      <Typography sx={{ ml: margenIzquierdo }} variant="verde15">
        {props.nombre}:
      </Typography>
      <Button
        variant="contained"
        //startIcon={<SendIcon />}
        sx={{ mt: -0.5, ml: 1, mr: .5, width: 110, height: 30 }}
        onClick={handleClickOpen}
      >
        {props.fecha}
      </Button>
      <Dialog
        open={open}
        onClose={handleAbandonar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'span'}>
            <LocalizationProvider sx={{ mt: 2 }}
              dateAdapter={AdapterDayjs}
              adapterLocale={state.idioma.substr(0, 2).toLowerCase()}>
              <DateCalendar
                value={value}
                onChange={(newValue) => setValue(newValue)}
              />
            </LocalizationProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAbandonar}>{t("MisInformes.Abandonar")}</Button>
          <Button onClick={handleGuardar} autoFocus>{t("MisInformes.Aceptar")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>

  )
}
export default withTranslation()(BotonFecha)