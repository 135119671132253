import config from './../config/config.js'

const rutaApi = config.rutaApis + '/pedidos'


const countLinPedMesProvCoor = (params) => {
  return fetch(rutaApi + '/countLinPedMesProv/' + params.proveedor + '/' + params.esProductor + '/' + params.mes, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}

///////////////////////////////////////////////
// Calendario e Informe de Productos Pendientes
const countLinPedMesPenProvPro = (params) => {
  return fetch(rutaApi + '/countLinPedMesPenProvPro/' + params.proveedor + '/' + params.esProductor + '/' + params.mes, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}
const listaLinPedDiaPenProvPro = (params) => {
  return fetch(rutaApi + '/listaLinPedDiaPenProvPro/' + params.proveedor + '/' + params.esProductor + '/' + params.dia + '/' + params.idioma, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}


const listaLinPedDiaPenProvProCli = (params) => {
  return fetch(rutaApi + '/listaLinPedDiaPenProvProCli/' + params.proveedor + '/' + params.esProductor + '/' + params.dia + '/' + params.idioma, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}


//////////////////////////////////////////////////////////
// Informe Lineas de Pedidos por Proveedor con Incidencias
const listaLinPedIncProvCoor = (params) => {
  //console.log(rutaApi + '/listaLinPedIncProvCoor/' + params.proveedor + '/' + params.esProductor + '/' + params.diaDesde + '/' + params.diaHasta + '/' + params.idioma)
  return fetch(rutaApi + '/listaLinPedIncProvCoor/' + params.proveedor + '/' + params.esProductor + '/' + params.diaDesde + '/' + params.diaHasta + '/' + params.idioma, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}


//////////////////////////////////////////////////////////
// Informe Lineas de Pedidos por Cliente
const listaVentasClientesProvCoor = (params) => {
  return fetch(rutaApi + '/listaVentasClientesProvCoor/' + params.proveedor + '/' + params.esProductor + '/' + params.mercado + '/' + params.diaDesde + '/' + params.diaHasta + '/' + params.idioma, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



//////////////////////////////////////////////////////////
// Informe Lineas de Pedidos por Producto
const listaVentasProductosProvCoor = (params) => {
  return fetch(rutaApi + '/listaVentasProductosProvCoor/' + params.tipoInforme + '/' + params.proveedor + '/' + params.esProductor + '/' + params.mercado + '/' + params.diaDesde + '/' + params.diaHasta + '/' + params.idioma, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



/////////////////////////////////////////////////////////
// Informe Lineas de Pedidos
const listaLinPedProvCoor = (params) => {
  return fetch(rutaApi + '/listaLinPedProvCoor/' + params.tipoInforme + '/' + params.incidencia + '/' + params.proveedor + '/' + params.esProductor + '/' + params.mercado + '/' + params.diaDesde + '/' + params.diaHasta + '/' + params.idioma + '/' + params.agrupacion, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}

const listaLinPedDiaProvCoor = (params) => {
  return fetch(rutaApi + '/listaLinPedDiaProvCoor/' + params.proveedor + '/' + params.esProductor + '/' + params.dia + '/' + params.idioma, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const updateLineasPedidos = (params) => {
  return fetch(rutaApi + '/updateLineasPedidos', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const crearNuevoPedido = (params) => {
  return fetch(rutaApi + '/crearNuevoPedido', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const cambiarFechaEntrega = (params) => {
  return fetch(rutaApi + '/cambiarFechaEntrega', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const countPedMesCoor = (params) => {
  return fetch(rutaApi + '/countPedMesCoor/' + params.coordinador + '/' + params.mes, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}


const listaPedDiaCoor = (params) => {
  return fetch(rutaApi + '/listaPedDiaCoor/' + params.coordinador + '/' + params.dia, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



export {
  countLinPedMesProvCoor,
  countLinPedMesPenProvPro,
  listaLinPedDiaPenProvPro,
  listaLinPedDiaPenProvProCli,
  listaLinPedDiaProvCoor,
  listaLinPedIncProvCoor,
  listaVentasClientesProvCoor,
  listaVentasProductosProvCoor,
  listaLinPedProvCoor,
  updateLineasPedidos,
  crearNuevoPedido,
  cambiarFechaEntrega,
  countPedMesCoor,
  listaPedDiaCoor
}