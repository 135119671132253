import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import BotonFecha from "./../../components/BotonFecha.js";
import BotonPdf from "./../../components/BotonPdf.js";
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { listaEntregasProvCoor } from './../../apis/api-entrega.js'

import config from './../../config/config.js'
import { FechaDdMmAAAA, FechaAaaaMmDd } from "./../../config/funcionesPropias.js";


const InfEntregas = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);


    var date = new Date()
    // Prepara las posibilidades de las capturas de Mercados
    var listaMercados = []
    for (let i = 0; i < state.mercadosProveedor.length; i++) {
        if (i === 0) {
            listaMercados.push(t("MisInformes.Todos"))
        }
        listaMercados.push(
            state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
        )
    }

    const [estado, setEstado] = useState(
        {
            carga: true,
            fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
            fechaFin: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            mercado: 0,
            seleccionMercados: listaMercados,
            lineasEntregas: []
        }
    )


    function actualizadorFechaDesde(fecha) {
        estado.fechaInicio = fecha
        setEstado({ ...estado })
        loadLineasEntregasIniciales()
    }

    function actualizadorFechaHasta(fecha) {
        estado.fechaFin = fecha
        setEstado({ ...estado })
        loadLineasEntregasIniciales()
    }


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        loadLineasEntregasIniciales()
    }


    useEffect(() => {
        if (estado.carga) {
            loadLineasEntregasIniciales()
            // Actualiza el estado sin Repintar. //
            estado.carga = false
        }
    })


    // Contar los productos para establecer el calendario
    async function loadLineasEntregasIniciales() {
        // Vacia lo que podríamos tener previamente
        if (estado.lineasEntregas.length > 0) {
            setEstado({ ...estado, lineasEntregas: [] })
            actions.cambiarMensaje("")
        }
        //
        let mercadosFiltro
        if (state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod
            await listaEntregasProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", mercado: mercadosFiltro, diaDesde: FechaAaaaMmDd(estado.fechaInicio), diaHasta: FechaAaaaMmDd(estado.fechaFin), idioma: state.idioma }).then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    //console.log('datos tras buscar = ', data)
                    preparaVisualizacion(data)
                }
            })
        }
    }

    
    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacion(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let tpFecha1, tpFecha2
            //
            for (let i = 0; i < data.length; i++) {
                tpFecha1 = FechaDdMmAAAA(new Date(data[i].EntFEn))
                tpFecha2 = tpFecha1.substr(6, 4) + tpFecha1.substr(3, 2) + tpFecha1.substr(0, 2)
                resultado.push({
                    Fecha: tpFecha1,
                    Mercado: data[i].EntMer,
                    NombreMercado: state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE,
                    Fichero: data[i].EntRep + "_" + data[i].EntMer + "_" + tpFecha2


                })
            }
            estado.lineasEntregas = resultado
            // Fuerza Renderizado
            setEstado({ ...estado, lineasEntregas: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayEntregas"))
        }
    }


    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisInformes.HojasDeEntregas")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 2 / 3, display: 'flex', /*justifyContent: 'center'*/ }}>
                    <Box>
                        <BotonFecha
                            //margenI={5}
                            nombre={t("MisInformes.Desde")}
                            fecha={FechaDdMmAAAA(estado.fechaInicio)}
                            actualizadorFecha={actualizadorFechaDesde}
                        />
                    </Box>
                    <Box>
                        <BotonFecha
                            margenI={5}
                            nombre={t("MisInformes.Hasta")}
                            fecha={FechaDdMmAAAA(estado.fechaFin)}
                            actualizadorFecha={actualizadorFechaHasta}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    <Lista
                        elementos={estado.seleccionMercados}
                        accion={handleFiltroMercado}
                        activos={estado.mercado}
                    />
                </Box>
            </Box>
        </Fragment>
    )


    const listaLineasListado = (
        estado.lineasEntregas.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="azul15">{t("MisInformes.FechaEntrega")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Mercado")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{""}</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {estado.lineasEntregas.map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{l.Fecha}</TableCell>
                                    <TableCell>{l.Mercado + ' ' + l.NombreMercado}</TableCell>
                                    <TableCell align="right"> <BotonPdf fichero={config.rutaCarpetaFicherosEntregas + '/' + l.Fichero + '.pdf'} /></TableCell>

                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaLineasListado}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(InfEntregas)