import config from './../config/config.js'
const rutaApi = config.rutaApis + '/fechasEntrega'


const leeFechasEntrega = (params) => {
   return fetch(rutaApi + '/leeFechasEntrega/' + params.mercado , {
   method: 'GET',
 }).then((response) => {
   return response.json()
 }).catch((err) => console.log(err))
}


const updateFechasEntrega = (params) => {
  return fetch(rutaApi + '/updateFechasEntrega', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


export {
  leeFechasEntrega,
  updateFechasEntrega
}