import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import BotonFecha from "./../../components/BotonFecha.js";
import BotonXlsx from "./../../components/BotonXlsx.js";
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { listaLinPedProvCoor } from './../../apis/api-pedido.js'

import { FechaDdMmAAAA, FechaAaaaMmDd, RedondeaPrecio } from "./../../config/funcionesPropias.js";


const InfProductos = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);

    // Arrays necesarios en varios submódulos
    const tipoInforme = [
        t("MisInformes.Todos"),
        t("MisInformes.SinPreparar"),
        t("MisInformes.Preparados"),
        t("MisInformes.Servidos")
    ]

    const agrupandoArticulos = [
        t("MisInformes.Desglosado"),
        t("MisInformes.Agrupando")
    ]

    var date = new Date()
    /*
    // Prepara las posibilidades de las capturas de Mercados
    var listaMercados = []
    for (let i = 0; i < state.mercadosProveedor.length; i++) {
        if (i === 0) {
            listaMercados.push(t("MisInformes.Todos"))
        }
        listaMercados.push(
            state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
        )
    }
    */
    const [estado, setEstado] = useState(
        {
            //carga: true,
            informe: 0,
            agrupacion: 1,
            fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
            fechaFin: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            mercado: undefined,
            seleccionMercados: [],
            lineasPedidos: [],
            lineasExcel: []
        }
    )


    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        //console.log("CargaMercados", state.idioma)
        if (state.mercadosProveedor.length > 0) {
            // Prepara las posibilidades de las capturas de Mercados
            var listaMercados = [t("MisInformes.Todos")]
            state.mercadosProveedor.forEach(mercado => {
                listaMercados.push(state.idioma === "Es" ? mercado.MerDesC : mercado.MerDesE)
            });
            /*
            var listaMercados = []
            for (let i = 0; i < state.mercadosProveedor.length; i++) {
                if (i === 0) {
                    listaMercados.push(t("MisInformes.Todos"))
                }
                listaMercados.push(
                    state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
                )
            }
            */
            let mer = estado.mercado === undefined ? 1 : estado.mercado
            setEstado(estado => ({ ...estado, mercado: mer, seleccionMercados: listaMercados }))
        } else {
            actions.cambiarMensaje(t("FechasEntrega.NoHayMercados"))
        }
    }, [state.mercadosProveedor, state.idioma, actions, t])



    useEffect(() => {
        //if (estado.carga) {
        loadLineasPedidosIniciales()
        // Actualiza el estado sin Repintar. //
        //    estado.carga = false
        //}
    }, [estado.informe, estado.agrupacion, estado.fechaInicio, estado.FechaFin, estado.mercado, state.idioma])



    //////////////////////////////////
    /// Filtrar Desde-Hasta Fechas ///
    //////////////////////////////////
    function actualizadorFechaDesde(fecha) {
        estado.fechaInicio = fecha
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }

    function actualizadorFechaHasta(fecha) {
        estado.fechaFin = fecha
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }


    //////////////////////////////////////////////
    /// Selecciona filtro por tipo de Informe. ///
    //////////////////////////////////////////////
    const handleFiltroInforme = event => {
        estado.informe = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }

    ////////////////////////////////////////////////
    /// Selecciona filtro por tipo de Agrupando ///
    ////////////////////////////////////////////////
    const handleFiltroAgrupandoArticulos = event => {
        estado.agrupacion = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }


    // Contar los productos para establecer el calendario
    async function loadLineasPedidosIniciales() {
        // Vacia lo que podríamos tener previamente
        if (estado.lineasPedidos.length > 0) {
            setEstado({ ...estado, lineasPedidos: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        //
        let mercadosFiltro
        if (estado.mercado !== undefined && state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod
            await listaLinPedProvCoor({ tipoInforme: estado.informe, proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", mercado: mercadosFiltro, diaDesde: FechaAaaaMmDd(estado.fechaInicio), diaHasta: FechaAaaaMmDd(estado.fechaFin), idioma: state.idioma, agrupacion: estado.agrupacion }).then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    if (data.length > 0) {
                        // Viene ordenado por Fecha Entrega +  Mercado + Nombre del producto
                        // Agrupar por esos 3 campos
                        let elemento = ''
                        let data1 = []
                        //console.log("data", data)
                        // Desglosado: Ordenado por Fecha, Mercado, Producto, Lote y Precio. //
                        if (estado.agrupacion === 0) {
                            data.forEach((l, i) => {
                                if (elemento !== l.PedFEn.slice(0, 10) + l.MerCod + l.LinArt + l.LinLot + l.LinPre) {
                                    data1.push({
                                        "Fecha": l.PedFEn.slice(0, 10),
                                        "CodigoMercado": l.MerCod,
                                        "Mercado": state.idioma === 'Es' ? l.MerDesC : l.MerDesE,
                                        "CodigoProducto": l.LinArt,
                                        "NombreProducto": state.idioma === 'Es' ? l.ProDesC : l.ProDesE,
                                        "Lote": l.LinLot,
                                        "NumeroPedidos": 1,
                                        "CantidadPedida": Number(l.LinCan),
                                        "CantidadEntregada": Number(l.LinEnt),
                                        "CantidadServida": Number(l.LinSer),
                                        "Unidad": state.uniAVer[l.ProUni],
                                        "Precio": Number(data[i].LinPre),
                                        "Importe": data[i].LinSer !== "" ? Number(data[i].LinPre) * Number(data[i].LinSer) : 0
                                    })
                                    elemento = l.PedFEn.slice(0, 10) + l.MerCod + l.LinArt + l.LinLot + l.LinPre
                                } else {
                                    data1[data1.length - 1].CantidadPedida += Number(l.LinCan)
                                    data1[data1.length - 1].CantidadEntregada += Number(l.LinEnt)
                                    data1[data1.length - 1].CantidadServida += Number(l.LinSer)
                                    data1[data1.length - 1].Importe += data[i].LinSer !== "" ? Number(data[i].LinPre) * Number(data[i].LinSer) : 0
                                    data1[data1.length - 1].NumeroPedidos += 1
                                }
                            })
                        }
                        // Agrupado. Ordenado por Mercado, Producto, Lote y Precio. //                        
                        else {
                            data.forEach((l, i) => {
                                if (elemento !== l.MerCod + l.LinArt + l.LinLot + l.LinPre) {
                                    data1.push({
                                        "CodigoMercado": l.MerCod,
                                        "Mercado": state.idioma === 'Es' ? l.MerDesC : l.MerDesE,
                                        "CodigoProducto": l.LinArt,
                                        "NombreProducto": state.idioma === 'Es' ? l.ProDesC : l.ProDesE,
                                        "Lote": l.LinLot,
                                        "NumeroPedidos": 1,
                                        "CantidadPedida": Number(l.LinCan),
                                        "CantidadEntregada": Number(l.LinEnt),
                                        "CantidadServida": Number(l.LinSer),
                                        "Unidad": state.uniAVer[l.ProUni],
                                        "Precio": Number(data[i].LinPre),
                                        "Importe": data[i].LinSer !== "" ? Number(data[i].LinPre) * Number(data[i].LinSer) : 0
                                    })
                                    elemento = l.MerCod + l.LinArt + l.LinLot + l.LinPre
                                } else {
                                    data1[data1.length - 1].CantidadPedida += Number(l.LinCan)
                                    data1[data1.length - 1].CantidadEntregada += Number(l.LinEnt)
                                    data1[data1.length - 1].CantidadServida += Number(l.LinSer)
                                    data1[data1.length - 1].Importe += data[i].LinSer !== "" ? Number(data[i].LinPre) * Number(data[i].LinSer) : 0
                                    data1[data1.length - 1].NumeroPedidos += 1
                                }
                            })
                        }
                        data = data1
                    }
                    preparaVisualizacionYExcel(data)
                }
            })
        }
    }



    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacionYExcel(data) {
        // 0 es desglosado
        if (estado.agrupacion === 0) {
            prepVisExcelDesglosado(data)
        } else {
            prepVisExcelAgrupado(data)
        }
    }



    function prepVisExcelAgrupado(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisInformes.Mercado"),
                t("MisInformes.Producto"),
                t("MisInformes.Lote"),
                t("MisInformes.NumeroPedidos"),
                t("MisInformes.CantidadPedida"),
                t("MisInformes.Unidad"),
                t("MisInformes.CantidadEntregada"),
                t("MisInformes.CantidadServida"),
                t("MisInformes.Precio"),
                t("MisInformes.ImporteServido")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                resultado.push({
                    Mercado: data[i].Mercado,
                    Producto: data[i].NombreProducto + "(" + data[i].CodigoProducto + ")",
                    Lote: data[i].Lote,
                    NumeroPedidos: data[i].NumeroPedidos,
                    CantidadPedida: data[i].CantidadPedida,
                    CantidadEntregada: data[i].CantidadEntregada,
                    CantidadServida: data[i].CantidadServida,
                    Unidad: data[i].Unidad,
                    Precio: data[i].Precio,
                    Importe: data[i].Importe
                })
                resultadoExcel.push({})
                resultadoExcel[i][cabeceraExcel[0]] = data[i].Mercado
                resultadoExcel[i][cabeceraExcel[1]] = data[i].NombreProducto + "(" + data[i].CodigoProducto + ")"
                resultadoExcel[i][cabeceraExcel[2]] = data[i].Lote
                resultadoExcel[i][cabeceraExcel[3]] = data[i].NumeroPedidos
                resultadoExcel[i][cabeceraExcel[4]] = data[i].CantidadPedida
                resultadoExcel[i][cabeceraExcel[5]] = data[i].Unidad
                resultadoExcel[i][cabeceraExcel[6]] = data[i].CantidadEntregada
                resultadoExcel[i][cabeceraExcel[7]] = data[i].CantidadServida
                resultadoExcel[i][cabeceraExcel[8]] = data[i].Precio
                resultadoExcel[i][cabeceraExcel[9]] = data[i].Importe
            }
            estado.lineasPedidos = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }



    function prepVisExcelDesglosado(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisInformes.FechaEntrega"),
                t("MisInformes.Mercado"),
                t("MisInformes.Producto"),
                t("MisInformes.Lote"),
                t("MisInformes.NumeroPedidos"),
                t("MisInformes.CantidadPedida"),
                t("MisInformes.Unidad"),
                t("MisInformes.CantidadEntregada"),
                t("MisInformes.CantidadServida"),
                t("MisInformes.Precio"),
                t("MisInformes.ImporteServido")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                resultado.push({
                    Fecha: FechaDdMmAAAA(new Date(data[i].Fecha)),
                    Mercado: data[i].Mercado,
                    Producto: data[i].NombreProducto + "(" + data[i].CodigoProducto + ")",
                    Lote: data[i].Lote,
                    NumeroPedidos: data[i].NumeroPedidos,
                    CantidadPedida: data[i].CantidadPedida,
                    CantidadEntregada: data[i].CantidadEntregada,
                    CantidadServida: data[i].CantidadServida,
                    Unidad: data[i].Unidad,
                    Precio: data[i].Precio,
                    Importe: data[i].Importe
                })
                resultadoExcel.push({})
                resultadoExcel[i][cabeceraExcel[0]] = FechaDdMmAAAA(new Date(data[i].Fecha))
                resultadoExcel[i][cabeceraExcel[1]] = data[i].Mercado
                resultadoExcel[i][cabeceraExcel[2]] = data[i].NombreProducto + "(" + data[i].CodigoProducto + ")"
                resultadoExcel[i][cabeceraExcel[3]] = data[i].Lote
                resultadoExcel[i][cabeceraExcel[4]] = data[i].NumeroPedidos
                resultadoExcel[i][cabeceraExcel[5]] = data[i].CantidadPedida
                resultadoExcel[i][cabeceraExcel[6]] = data[i].Unidad
                resultadoExcel[i][cabeceraExcel[7]] = data[i].CantidadEntregada
                resultadoExcel[i][cabeceraExcel[8]] = data[i].CantidadServida
                resultadoExcel[i][cabeceraExcel[9]] = data[i].Precio
                resultadoExcel[i][cabeceraExcel[10]] = data[i].Importe
            }
            estado.lineasPedidos = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }



    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisInformes.Productos")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 2 / 3, display: 'flex', /*justifyContent: 'center'*/ }}>
                    <Box>
                        <BotonFecha
                            //margenI={5}
                            nombre={t("MisInformes.Desde")}
                            fecha={FechaDdMmAAAA(estado.fechaInicio)}
                            actualizadorFecha={actualizadorFechaDesde}
                        />
                    </Box>
                    <Box>
                        <BotonFecha
                            margenI={5}
                            nombre={t("MisInformes.Hasta")}
                            fecha={FechaDdMmAAAA(estado.fechaFin)}
                            actualizadorFecha={actualizadorFechaHasta}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Ver")}:
                    </Typography>
                    <Lista
                        id={"Inf"}
                        elementos={tipoInforme}
                        accion={handleFiltroInforme}
                        activos={estado.informe}
                    />
                </Box>
                <Box sx={{ ml: 3 }}>
                    <BotonXlsx data={estado.lineasExcel} filename={t("MisProductos.Productos")} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    {estado.mercado !== undefined &&
                        <Lista
                            id={"Mer"}
                            elementos={estado.seleccionMercados}
                            accion={handleFiltroMercado}
                            activos={estado.mercado}
                        />
                    }
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Informe")}:
                    </Typography>
                    <Lista
                        id={"Art"}
                        elementos={agrupandoArticulos}
                        accion={handleFiltroAgrupandoArticulos}
                        activos={estado.agrupacion}
                    />
                </Box>
            </Box>
        </Fragment>
    )



    const listaLineasListado = (
        estado.lineasPedidos.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="lineas de pedidos">
                        <TableHead>
                            <TableRow>
                                {estado.agrupacion === 0 ? <TableCell><Typography variant="azul15">{t("MisInformes.FechaEntrega")}</Typography></TableCell> : ""}
                                <TableCell><Typography variant="azul15">{t("MisInformes.Mercado")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Producto")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Lote")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.Pedidos")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadPedida")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadPreparadaI")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadServida")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.Precio")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.ImporteServido")}</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {estado.lineasPedidos.map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {estado.agrupacion === 0 ? <TableCell>{l.Fecha}</TableCell> : ""}
                                    <TableCell>{l.Mercado}</TableCell>
                                    <TableCell>{l.Producto}</TableCell>
                                    <TableCell>{l.Lote}</TableCell>
                                    <TableCell align="right">{l.NumeroPedidos}</TableCell>
                                    <TableCell align="right">{l.CantidadPedida + " " + l.Unidad}</TableCell>
                                    <TableCell align="right">{l.CantidadEntregada}</TableCell>
                                    <TableCell align="right">{l.CantidadServida}</TableCell>
                                    <TableCell align="right">{RedondeaPrecio(Number(l.Precio), 2)}{" €"}</TableCell>
                                    <TableCell align="right">{l.Importe !== 0 ? RedondeaPrecio(l.Importe, 2) + " €" : ""}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    )



    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaLineasListado}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(InfProductos)