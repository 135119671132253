import config from './../config/config.js'

const rutaApi = config.rutaApis + '/mercados'


const listaMercados = () => {
  return fetch(rutaApi + '/listaMercados', {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => { console.log(err) })
}


const listaMercadosProvCoor = (params) => {
  return fetch(rutaApi + '/listaMercadosProvCoor/' + params.esProductor + '/' + params.proveedor, {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => { console.log(err) })
}


const listaFPagoMercados = (params) => {
  return fetch(rutaApi + '/listaFPagoMercados/' + params.mercado, {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => { console.log(err) })
}

export {
  listaMercados,
  listaMercadosProvCoor,
  listaFPagoMercados
}