//import liraries
import React, { Fragment, useState, useEffect } from "react";
import { useStore } from 'react-create-use-store';
import { withTranslation } from 'react-i18next'

import { DayPicker } from "react-day-picker";
import { isSameDay, parseISO, format } from "date-fns";
import { es, eu } from "date-fns/locale";
import { default as defaultStyles } from "./style.module.css";


import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';


import ThemeGeroa from './../../ThemeGeroa.js'
import store from './../../adderStore.js'
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { leeFechasEntrega, updateFechasEntrega } from './../../apis/api-fechasEntrega.js'


// create a component
const FechasEntrega = ({ t, ...props }) => {
    const { state, actions } = useStore(store);
    const [mercado, setMercado] = useState(undefined)
    const [botGuardar, setBotGuardar] = useState(true)

    // Fechas Entrega. //
    const [fecEntregas, setFecEntregas] = useState([{ elemento: new Date(), seleccion: false }])

    // Forzar Mes: Inicio, Desde y Hasta
    let mes = {} // {inicio: fecha, desde: fecha, hasta: fecha}
    let fecha = new Date()
    mes.inicio = new Date(fecha.getFullYear(), fecha.getMonth())
    //fecha = fechasEntrega[0] || fecha
    mes.desde = new Date(fecha.getFullYear(), fecha.getMonth())
    //fecha = fechasEntrega[fechasEntrega.length - 1] || fecha
    mes.hasta = new Date(fecha.setMonth(fecha.getMonth() + 4))

    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        if (state.mercadosProveedor.length > 0) {
            actions.cambiarMensaje("")
            setMercado(0)
        } else {
            actions.cambiarMensaje(t("FechasEntrega.NoHayMercados"))
        }
    }, [state.mercadosProveedor, actions, t])

    // Recargar cuando cambia el mercado en el filtro. //
    useEffect(() => {
        // Cargar las Fechas del Mercado Seleccionado. //
        async function cargaFechasMercado() {
            if (mercado !== undefined && state.mercadosProveedor.length > 0) {
                let codigoMercado = state.mercadosProveedor[Number(mercado)].MerCod
                // Inicializar las Fechas de Entrega del Mercado. //
                let fechas = []
                await leeFechasEntrega({ mercado: codigoMercado }).then((data) => {
                    if (data.error) {
                        console.log(data.error)
                    }
                    else {
                        // Eliminar las Fechas anteriores a Hoy: AAAA-MM-DD. //
                        let hoy = format(new Date(), "yyyy-MM-dd")
                        /// Error con Map: devuelve UNDEFINED si filtramos. Hacer con ForEach. 
                        data.forEach(f => {
                            if (f.FeMFec >= hoy) {
                                // Convertir a Fechas para ver en el calendario. //
                                fechas.push(parseISO(f.FeMFec))
                            }
                        })
                        //console.log("data", data, "fechas", fechas)
                    }
                })
                setFecEntregas(fechas)
            }
        }
        cargaFechasMercado()

    }, [mercado, state.mercadosProveedor])


    // Al hacer click en algun día, lo marca/desmarca. //
    const handleDayClick = (day, modifiers) => {
        // Solo marcamos dias mayores o iguales a hoy. //
        let hoy = format(new Date(), "yyyy-MM-dd")
        if (format(day, "yyyy-MM-dd") >= hoy) {
            const fechas = [...fecEntregas];
            if (modifiers.selected) {
                const index = fecEntregas.findIndex((d) => isSameDay(day, d));
                fechas.splice(index, 1);
            } else {
                fechas.push(day);
            }
            setFecEntregas(fechas);
            // DesHabilitar Si No hay fechas Marcadas. //
            setBotGuardar(fechas.length === 0)
        }
    };


    // Activar Boton Guardar. //
    const handleGuardar = async () => {

        actions.cambiarMensaje("guardando...")
        // Deshabilitar Botón //
        setBotGuardar(true);
        // Configurar objeto params. //
        let params = {
            mercado: state.mercadosProveedor[Number(mercado)].MerCod,
            entregas: fecEntregas.map(f => {
                return format(f, "yyyy-MM-dd")
            })
        }
        await updateFechasEntrega(params)
            .then((data) => {
                if (data.error) {
                    console.log("error = ", data.error, data)
                }
                actions.cambiarMensaje("")
            })
    };


    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("FechasEntrega.FechasEntrega")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                {state.mercadosProveedor.length > 0 && mercado !== undefined &&
                    <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                        <Typography variant="verde15">
                            {t("FechasEntrega.Mercado")}:
                        </Typography>
                        <Lista
                            id={"Mer"}
                            elementos={state.mercadosProveedor.map(m => state.idioma === "Es" ? m.MerDesC : m.MerDesE)}
                            accion={event => setMercado(event.target.value)}
                            activos={mercado}
                        />
                    </Box>
                }
            </Box>
        </Fragment>
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {state.mercadosProveedor.length > 0 && mercado !== undefined && (
                    <Container>
                        <Grid sx={{ display: 'flex', justifyContent: "center" }}>
                            <DayPicker
                                // Estilo por defecto
                                classNames={defaultStyles}
                                // idioma. //
                                locale={state.idioma === 'Es' ? es : eu}
                                // mes inicio, desde-hasta. //
                                initialMonth={mes.inicio}
                                fromMonth={mes.desde}
                                toMonth={mes.hasta}
                                // 
                                onDayClick={handleDayClick}
                                modifiers={{ selected: fecEntregas }}
                            />
                            <br />
                        </Grid>
                        <Grid sx={{ display: 'flex', justifyContent: "center" }}>
                            <Button
                                disabled={botGuardar}
                                variant="contained"
                                sx={{ width: 110, height: 30 }}
                                onClick={handleGuardar}
                            >
                                {t("General.Guardar")}
                            </Button>
                        </Grid>
                    </Container>
                )}
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(FechasEntrega);