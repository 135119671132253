import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import BotonFecha from "./../../components/BotonFecha.js";
import BotonXlsx from "./../../components/BotonXlsx.js";
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { listaLinPedProvCoor } from './../../apis/api-pedido.js'

import { FechaDdMmAAAA, FechaAaaaMmDd, RedondeaPrecio, RedondeaNumero } from "./../../config/funcionesPropias.js";


const InfPedidos = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);

    // Arrays necesarios en varios submódulos
    const tipoInforme = [
        t("MisInformes.Todos"),
        t("MisInformes.SinPreparar"),
        t("MisInformes.Preparados"),
        t("MisInformes.Servidos")
    ]

    const tipoIncidencia = [
        t("MisInformes.Todos"),
        t("MisInformes.Si"),
        t("MisInformes.No")
    ]


    var date = new Date()
    // Prepara las posibilidades de las capturas de Mercados
    var listaMercados = []
    for (let i = 0; i < state.mercadosProveedor.length; i++) {
        if (i === 0) {
            listaMercados.push(t("MisInformes.Todos"))
        }
        listaMercados.push(
            state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
        )
    }
    const [estado, setEstado] = useState(
        {
            carga: true,
            informe: 0,
            incidencia: 0,
            fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
            fechaFin: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            mercado: 0,
            seleccionMercados: listaMercados,
            lineasPedidos: [],
            lineasExcel: []
        }
    )



    function actualizadorFechaDesde(fecha) {
        estado.fechaInicio = fecha
        setEstado({ ...estado })
        loadLineasPedidosIniciales()
    }


    function actualizadorFechaHasta(fecha) {
        estado.fechaFin = fecha
        setEstado({ ...estado })
        loadLineasPedidosIniciales()
    }


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        loadLineasPedidosIniciales()
    }


    //////////////////////////////////////////////
    /// Selecciona filtro por tipo de Informe. ///
    //////////////////////////////////////////////
    const handleFiltroInforme = event => {
        estado.informe = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        loadLineasPedidosIniciales()
    }


    ////////////////////////////////////////////////
    /// Selecciona filtro por tipo de Incidencia ///
    ////////////////////////////////////////////////
    const handleFiltroIncidencia = event => {
        estado.incidencia = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        loadLineasPedidosIniciales()
    }


    useEffect(() => {
        if (estado.carga) {
            loadLineasPedidosIniciales()
            // Actualiza el estado sin Repintar. //
            estado.carga = false
        }
    })



    // Contar los productos para establecer el calendario
    async function loadLineasPedidosIniciales() {
        // Vacia lo que podríamos tener previamente
        if (estado.lineasPedidos.length > 0) {
            setEstado({ ...estado, lineasPedidos: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        //
        let mercadosFiltro
        if (state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod
            await listaLinPedProvCoor({ tipoInforme: estado.informe, incidencia: estado.incidencia, proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", mercado: mercadosFiltro, diaDesde: FechaAaaaMmDd(estado.fechaInicio), diaHasta: FechaAaaaMmDd(estado.fechaFin), idioma: state.idioma, agrupacion: 2 }).then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    preparaVisualizacionYExcel(data)
                }
            })
        }
    }



    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacionYExcel(data) {
        prepVisExcelDesglosado(data)
    }



    function prepVisExcelDesglosado(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisInformes.FechaEntrega"),
                t("MisInformes.Mercado"),
                t("MisInformes.Pedido"),
                t("MisInformes.Cliente"),
                t("MisInformes.Productor"),
                t("MisInformes.Producto"),
                t("MisInformes.Precio"),
                t("MisInformes.CantidadPedida"),
                t("MisInformes.Unidad"),
                t("MisInformes.CantidadEntregada"),
                t("MisInformes.CantidadServida"),
                t("MisInformes.Importe"),
                t("MisInformes.Lote"),
                t("MisInformes.Direccion"),
                t("MisInformes.Poblacion"),
                t("MisInformes.Incidencia")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                let unidad = state.uniAVer[data[i].ProUni]
                let importe = RedondeaNumero(Number(data[i].LinPre) * Number(data[i].LinSer), 2)
                resultado.push({
                    Fecha: FechaDdMmAAAA(new Date(data[i].PedFEn)),
                    Mercado: state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE,
                    Pedido: data[i].LinNum,
                    Cliente: data[i].PedRaz,
                    Productor: data[i].UsuRazonSocial,
                    Producto: (state.idioma === "Es" ? data[i].ProDesC : data[i].ProDesE) + " (" + data[i].LinArt + ")",
                    Precio: data[i].LinPre,
                    CantidadPedida: data[i].LinCan + " " + unidad,
                    CantidadEntregada: data[i].LinEnt >= "0" ? data[i].LinEnt + " " + unidad : "",
                    CantidadServida: data[i].LinSer >= "0" ? data[i].LinSer + " " + unidad : "",
                    Importe: data[i].LinSer !== "" ? RedondeaPrecio(Number(data[i].LinPre) * Number(data[i].LinSer), 2) + " €" : "",
                    Lote: data[i].LinLot,
                    Direccion: data[i].PedCal,
                    Poblacion: data[i].PedPob,
                    Incidencia: data[i].LinInc
                })
                resultadoExcel.push({})
                resultadoExcel[i][cabeceraExcel[0]] = FechaDdMmAAAA(new Date(data[i].PedFEn))
                resultadoExcel[i][cabeceraExcel[1]] = state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE
                resultadoExcel[i][cabeceraExcel[2]] = data[i].LinNum
                resultadoExcel[i][cabeceraExcel[3]] = data[i].PedRaz
                resultadoExcel[i][cabeceraExcel[4]] = data[i].UsuRazonSocial
                resultadoExcel[i][cabeceraExcel[5]] = (state.idioma === "Es" ? data[i].ProDesC : data[i].ProDesE) + " (" + data[i].LinArt + ")"
                resultadoExcel[i][cabeceraExcel[6]] = RedondeaPrecio(Number(data[i].LinPre), 2)
                resultadoExcel[i][cabeceraExcel[7]] = Number(data[i].LinCan)
                resultadoExcel[i][cabeceraExcel[8]] = unidad
                resultadoExcel[i][cabeceraExcel[9]] = data[i].LinEnt >= "0" ? Number(data[i].LinEnt) : ""
                resultadoExcel[i][cabeceraExcel[10]] = data[i].LinSer >= "0" ? Number(data[i].LinSer) : ""
                resultadoExcel[i][cabeceraExcel[11]] = data[i].LinSer !== "" ? importe : ""
                resultadoExcel[i][cabeceraExcel[12]] = data[i].LinLot
                resultadoExcel[i][cabeceraExcel[13]] = data[i].PedCal
                resultadoExcel[i][cabeceraExcel[14]] = data[i].PedPob
                resultadoExcel[i][cabeceraExcel[15]] = data[i].LinInc
            }
            estado.lineasPedidos = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }



    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisInformes.Pedidos")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 2 / 3, display: 'flex', /*justifyContent: 'center'*/ }}>
                    <Box>
                        <BotonFecha
                            //margenI={5}
                            nombre={t("MisInformes.Desde")}
                            fecha={FechaDdMmAAAA(estado.fechaInicio)}
                            actualizadorFecha={actualizadorFechaDesde}
                        />
                    </Box>
                    <Box>
                        <BotonFecha
                            margenI={5}
                            nombre={t("MisInformes.Hasta")}
                            fecha={FechaDdMmAAAA(estado.fechaFin)}
                            actualizadorFecha={actualizadorFechaHasta}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Ver")}:
                    </Typography>
                    <Lista
                        id={"Inf"}
                        elementos={tipoInforme}
                        accion={handleFiltroInforme}
                        activos={estado.informe}
                    />
                </Box>
                <Box sx={{ ml: 3 }}>
                    <BotonXlsx data={estado.lineasExcel} filename={t("MisInformes.Pedidos")} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    <Lista
                        id={"Mer"}
                        elementos={estado.seleccionMercados}
                        accion={handleFiltroMercado}
                        activos={estado.mercado}
                    />
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Incidencia")}:
                    </Typography>
                    <Lista
                        id={"Inc"}
                        elementos={tipoIncidencia}
                        accion={handleFiltroIncidencia}
                        activos={estado.incidencia}
                    />
                </Box>
            </Box>
        </Fragment>
    )



    const listaLineasListado = (
        estado.lineasPedidos.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="azul15">{t("MisInformes.FechaEntrega")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Mercado")}</Typography></TableCell>
                                {!state.esProductor && <TableCell sx={{ width: 150 }} ><Typography variant="azul15">{t("MisInformes.Productor")}</Typography></TableCell>}
                                <TableCell><Typography variant="azul15">{t("MisInformes.Pedido")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Cliente")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Producto")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.Precio")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadPedida")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadPreparadaI")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadServida")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.Importe")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Lote")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Incidencia")}</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {estado.lineasPedidos.map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{l.Fecha}</TableCell>
                                    <TableCell>{l.Mercado}</TableCell>
                                    {!state.esProductor && <TableCell>{l.Productor}</TableCell>}
                                    <TableCell>{l.Pedido}</TableCell>
                                    <TableCell>{l.Cliente}<br />{l.Direccion}{" - "}{l.Poblacion}</TableCell>
                                    <TableCell>{l.Producto}</TableCell>
                                    <TableCell>{RedondeaPrecio(Number(l.Precio), 2)}{" €"}</TableCell>
                                    <TableCell align="right">{l.CantidadPedida}</TableCell>
                                    <TableCell align="right">{l.CantidadEntregada}</TableCell>
                                    <TableCell align="right">{l.CantidadServida}</TableCell>
                                    <TableCell align="right">{l.Importe}</TableCell>
                                    <TableCell>{l.Lote}</TableCell>
                                    <TableCell align="right">{l.Incidencia}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    )



    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaLineasListado}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(InfPedidos)