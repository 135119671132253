import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useStore } from 'react-create-use-store';
import store from './../adderStore.js'


export default function MensajeCabecera({ ...props }) {
    const { state, /*actions*/ } = useStore(store)
    return (
        (state.mensaje !== "") ?
            <Grid item sx={{ mt: 5, ml: 5 }}>
                <Typography variant="rojo16" display="inline">
                    {state.mensaje} ...
                </Typography>
                {/*<br/>*/}
            </Grid>
            :
            <Fragment />
    )
}