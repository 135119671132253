import config from './../config/config.js'

const rutaApi = config.rutaApis + '/puntos'



//////////////////////////////////////////////////////////
// Informe Lineas de Pedidos por Proveedor con Incidencias
const listaPuntosProvCoor = (params) => {
  return fetch(rutaApi + '/listaPuntosProvCoor/' + params.proveedor + '/' + params.esProductor, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}


const updatePuntoProvCoor = (params) => {
  return fetch(rutaApi + '/updatePuntoProvCoor', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({productor: params.proveedor, esProductor: params.esProductor, punto: params.punto})
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}




const borrarFamiliasPuntosFecha = (params) => {
  return fetch(rutaApi + '/borrarFamiliasPuntosFecha', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({fecha: params.fecha})
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}
const borrarPunto = (params) => {
  return fetch(rutaApi + '/borrarPunto', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({numero: params.numero})
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}

export {
  listaPuntosProvCoor,
  updatePuntoProvCoor,
  borrarPunto,
  borrarFamiliasPuntosFecha
}