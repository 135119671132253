import React, { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import CambiaImagen from "../../components/CambiaImagen.js";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CajaTexto from './../../components/CajaTexto.js';
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ThemeGeroa from './../../ThemeGeroa.js'
import { withTranslation } from 'react-i18next'
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { PrecioConIva, FechaDdMmAAAA, FechaAaaaMmDd, FechaAaaaMmDdMmSs } from '../../config/funcionesPropias.js'
import { crearProductoTarifas, updateProductoTarifas } from './../../apis/api-producto.js'
import { useStore } from 'react-create-use-store'
import store from './../../adderStore.js'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import DialogTitle from '@mui/material/DialogTitle'

import config from './../../config/config.js'


let imagenProducto = ""

const DialogProducto = ({ t, ...props }) => {
    const tipo = props.tipo
    //const prod = { ...props.producto }
    const arrP = props.arrP

    const { state, /*actions*/ } = useStore(store);

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
            fontSize: 13,
            width: 'auto',
            padding: '2px 2px 2px 14px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const [ficha, setFicha] = useState({
        ...props.producto,
        ProFac: props.producto.ProFac + "",
        // Hacer copia del Array de Mercados, porque sino se pasa por referencia
        // y se actualiza el valor en el gancho recibido en props. 
        Mercados: props.producto.Mercados.map((m) => {
            // Quitar error navegador cuando las tarifas son nulas. //
            m.TarVal = (m.TarVal === null) ? undefined : m.TarVal
            m.TarVis = (m.TarVis === null) ? 0 : m.TarVis
            m.TarNov = (m.TarNov === null) ? 0 : m.TarNov
            return { ...m }
        })
    })
    const [/*foto*/, setFoto] = useState(config.rutaFotosProductos + props.producto.ProCod + '.jpg?f=' + (new Date().getTime()))

    const [open, setOpen] = useState(true)


    const handleCloseGuardar = async () => {
        if (ficha.ProDesC.trim() === '' || ficha.ProDesE.trim() === '') {
            alert("No puede dejar la descripción vacía.")
            return
        }
        if (Number(ficha.ProFac) !== 0 && ficha.ProUn2 === '') {
            alert(t("MisProductos.SiHayFactor"))
            return
        }
        // Si el factor es 0 vaciar Unidad. //
        if (Number(ficha.ProFac) <= 0) {
            ficha.ProFac = 0
            ficha.ProUn2 = ""
        }
        // Modificar todas las Tarifas para quitar el dato null //
        ficha.Mercados.forEach(tarifa => {
            if (tarifa.TarVal === null) {
                tarifa.TarVal = ''
            }
            if (tarifa.TarVis === null) {
                tarifa.TarVis = 0
            }
            if (tarifa.TarNov === null) {
                tarifa.TarNov = 0
            }
        });
        setOpen(false)
        if (tipo === "A") {
            // Estamos Añadiendo Producto
            //console.log('vamos a Añadir = ', ficha)
            await crearProductoTarifas({ productor: state.codProveedor, producto: ficha, imagen: imagenProducto })
                .then((data) => {
                    if (data.error) {
                        console.log("error = ", data.error)
                    }
                    else {
                        // Volver a Cargar Producto
                        ficha.modificado = false
                        imagenProducto = ""
                        // Actualizar Imagen. //
                        setFoto(config.rutaFotosProductos + ficha.ProCod + '.jpg?f=' + (new Date().getTime()))
                        // Actualizador y Recargas. //
                        props.actualizador(ficha, true, true)
                        ficha.modificacionActivo = false
                    }
                })
        } else {
            // Estamos Editando Producto
            if (ficha.modificado) {
                //console.log('vamos a Modificar = ', ficha)
                await updateProductoTarifas({ producto: ficha, imagen: imagenProducto })
                    .then((data) => {
                        if (data.error) {
                            console.log(data.error)
                        }
                        else {
                            // Volver a Cargar Producto
                            ficha.modificado = false
                            imagenProducto = ""
                            // Actualizar Imagen. //
                            setFoto(config.rutaFotosProductos + ficha.ProCod + '.jpg?f=' + (new Date().getTime()))
                            // Actualizador y Recarga. //
                            props.actualizador(ficha, true, true)
                        }
                    })
            }
        }
    }



    const handleCloseAbandonar = () => {
        // Dejar la ficha como entró. //
        //console.log(props.producto)
        //setFicha({ ...props.producto })
        //ficha.modificado = false
        setOpen(false)
        // No hace falta actualizar ni recargar. //
        props.actualizador(null, false, false)
    }


    const handleChangeProducto = (campo, m) => event => {
        //event.stopPropagation()
        if (campo === "ProVis") {
            ficha.ProVis = event.target.checked ? 1 : 0
        } else if (campo === "ProFam") {
            ficha.ProFam = event.target.value
        } else if (campo === "ProDesC") {
            ficha.ProDesC = event
        } else if (campo === "ProDesE") {
            ficha.ProDesE = event
        } else if (campo === "ProEIn") {
            // Hay que cambiar la fecha
            ficha.ProFEi = FechaAaaaMmDdMmSs(new Date())
            ficha.ProEIn = event
            ficha.ProExi = event
        } else if (campo === "ProExi") {
            ficha.ProExi = event
        } else if (campo === "ProLot") {
            ficha.ProLot = event
        } else if (campo === "ProUni") {
            ficha.ProUni = event.target.value
        } else if (campo === "ProFac") {
            ficha.ProFac = event
        } else if (campo === "ProUn2") {
            ficha.ProUn2 = event.target.value
        } else if (campo === "ProIva") {
            ficha.ProIva = event.target.value
            //ficha.ProIva = state.gruposIva[event.target.value].IvaCod
        } else if (campo === "TarVis") {
            ficha.Mercados[m].TarVis = event.target.checked ? 1 : 0
        } else if (campo === "TarNov") {
            ficha.Mercados[m].TarNov = event.target.checked ? 1 : 0
        } else if (campo === "TarVal") {
            ficha.Mercados[m].TarVal = event
            if (event > 0) {
                ficha.Mercados[m].TarVis = 1
            } else {
                ficha.Mercados[m].TarVis = 0
            }
        }
        //
        ficha.modificado = true
        setFicha({ ...ficha })
    }

    function nuevaImagen(img) {
        imagenProducto = img
        //
        ficha.modificado = true
        setFicha({ ...ficha })
    }

    let id = props.producto.ProCod

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Dialog
                open={open}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="azul13">{tipo === "E" ? t("MisProductos.Modificar") +" ("+ id +")" : t("MisProductos.Añadir")}</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ display: 'flex' }} height="50px">
                        <Box sx={{ width: 1 / 6 }}>
                            <CambiaImagen
                                codigo={id}
                                func={nuevaImagen}
                            />
                        </Box>
                        <Typography sx={{ ml: 5 }} variant="gris13">
                            {t("MisProductos.Familia")}:
                        </Typography>
                        <FormControl
                            sx={{ ml: 1, mt: - 0.5, mb: 3 }}
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                        >
                            <Select
                                id={"UniFam" + id}
                                //autoFocus
                                value={ficha.ProFam}
                                onChange={handleChangeProducto("ProFam")}
                                input={<BootstrapInput id={"MenuFam" + id} />}
                            >
                                {state.familias.map((f, i) => (
                                    <MenuItem value={f.FamCod} key={f.FamCod}> {state.idioma === "Es" ? f.FamDesC : f.FamDesE} </MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <br />
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="gris13" >
                            {t("MisProductos.NombreCastellano")}:
                        </Typography>
                        <FormControlLabel sx={{ ml: 2 }}
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={<CajaTexto
                                id={"ProDesC" + id}
                                tipo={"text"}
                                ancho={450}
                                valor={ficha.ProDesC}
                                accion={handleChangeProducto("ProDesC")}
                            />}
                        />
                    </Box>
                    <Typography variant="gris13" >
                        {t("MisProductos.NombreEuskera")}:
                    </Typography>
                    <FormControlLabel sx={{ ml: 2 }}
                        aria-label="Acknowledge"
                        onClick={event => event.stopPropagation()}
                        onFocus={event => event.stopPropagation()}
                        control={<CajaTexto
                            id={"ProDesE" + id}
                            tipo={"text"}
                            ancho={450}
                            valor={ficha.ProDesE}
                            accion={handleChangeProducto("ProDesE")}
                        />}
                    />
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="gris13" >
                            {t("MisProductos.ExistenciasAFecha") + " "}
                        </Typography>
                        <Typography variant="azul13" >
                            {state.idioma === "Es" ? FechaDdMmAAAA(new Date(ficha.ProFEi)) : FechaAaaaMmDd(new Date(ficha.ProFEi))}:
                        </Typography>
                        <FormControlLabel sx={{ ml: 1 }}
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={<CajaTexto
                                id={"ProEIn" + id}
                                tipo={"number"}
                                ancho={100}
                                valor={ficha.ProEIn}
                                accion={handleChangeProducto("ProEIn")}
                            />}
                        />


                        <br />
                        <Typography variant="gris13" >
                            {t("MisProductos.ExistenciasActuales")}:
                        </Typography>
                        <FormControlLabel sx={{ ml: 1 }}
                            aria-label="Acknowledge"
                            disabled={true}
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={<CajaTexto
                                id={"ProExi" + id}
                                tipo={"number"}
                                ancho={100}
                                valor={ficha.ProExi}
                                accion={handleChangeProducto("ProExi")}
                            />}
                        />
                        <Typography variant="gris13" >
                            {t("MisProductos.Lote")}:
                        </Typography>
                        <FormControlLabel sx={{ ml: 1 }}
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={<CajaTexto
                                id={"ProLote" + id}
                                tipo={"text"}
                                ancho={200}
                                valor={ficha.ProLot}
                                accion={handleChangeProducto("ProLot")}
                            />}
                        />
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Typography sx={{ ml: 0 }} variant="gris13" display="inline">
                            {t("MisProductos.Unidad")}:
                        </Typography>
                        <FormControl
                            sx={{ ml: 1, mt: - 0.5, mb: 3 }}
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                        >
                            <Select
                                id={"UniCod" + id}
                                value={ficha.ProUni}
                                //onChange={handleFiltroIvas}
                                onChange={handleChangeProducto("ProUni")}
                                input={<BootstrapInput id={"MenuUni" + id} />}
                            >
                                <MenuItem value={"0"} key={"0"}> {arrP.uniAVer[0]} </MenuItem>
                                <MenuItem value={"1"} key={"1"}> {arrP.uniAVer[1]} </MenuItem>
                                <MenuItem value={"2"} key={"2"}> {arrP.uniAVer[2]} </MenuItem>
                            </Select>
                        </FormControl>
                        <Typography variant="gris13" >
                            {"  " + t("MisProductos.Factor")}:
                        </Typography>
                        <FormControlLabel sx={{ ml: 0 }}
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={<CajaTexto
                                id={"Factor" + id}
                                tipo={"number"}
                                ancho={50}
                                valor={ficha.ProFac}
                                accion={handleChangeProducto("ProFac")}
                            />}
                        />
                        <Typography sx={{ ml: 0 }} variant="gris13" display="inline">
                            {t("MisProductos.Unidad2")}:
                        </Typography>
                        <FormControl
                            sx={{ ml: 1, mt: - 0.5, mb: 3 }}
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                        >
                            <Select
                                id={"Un2Cod" + id}
                                value={ficha.ProUn2}
                                //onChange={handleFiltroIvas}
                                onChange={handleChangeProducto("ProUn2")}
                                input={<BootstrapInput id={"MenuUn2" + id} />}
                            >
                                <MenuItem value={"0"} key={"0"}> {arrP.un2AVer[0]} </MenuItem>
                                <MenuItem value={"1"} key={"1"}> {arrP.un2AVer[1]} </MenuItem>
                                <MenuItem value={"2"} key={"2"}> {arrP.un2AVer[2]} </MenuItem>

                            </Select>
                        </FormControl>
                        <Typography sx={{ ml: 2 }} variant="gris13" display="inline">
                            {t("MisProductos.Iva")}:
                        </Typography>
                        <FormControl
                            sx={{ ml: 1, mt: - 0.5, mb: 0 }}
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                        >
                            <Select
                                id={"IvaCod" + id}
                                //value={BuscaIndice(state.gruposIva, 'IvaCod', ficha.ProIva)}
                                value={ficha.ProIva}
                                //onChange={handleFiltroIvas}
                                onChange={handleChangeProducto("ProIva")}
                                input={<BootstrapInput id={"MenuIva" + id} />}
                            >
                                {state.gruposIva.map((gi, i) => (
                                    <MenuItem value={gi.IvaCod} key={gi.IvaCod}> {state.idioma === "Es" ? gi.IvaDesC : gi.IvaDesE} </MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mt: 0 }}>
                        {ficha.Mercados.map((m, k) => (
                            <Fragment key={'m' + k}>
                                <Divider />
                                <Typography variant="azul13">{m.MerDesC}</Typography>
                                <Fragment>
                                    <Typography sx={{ ml: 5 }} variant="gris13" display="inline">
                                        {t("MisProductos.Visible")}:
                                    </Typography>
                                    <FormControlLabel
                                        sx={{ ml: 0.5 }}
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={<Checkbox
                                            id={"MerVis" + id + m.MerCod}
                                            checked={ficha.Mercados[k].TarVis === 1}
                                            onChange={handleChangeProducto("TarVis", k)}
                                        />}
                                    />
                                </Fragment>
                                <Fragment>
                                    <Typography sx={{ ml: 0 }} variant="gris13" display="inline">
                                        {t("MisProductos.Oferta")}:
                                    </Typography>
                                    <FormControlLabel
                                        sx={{ ml: 0.5 }}
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={<Checkbox
                                            id={"TarNov" + id + m.MerCod}
                                            checked={ficha.Mercados[k].TarNov === 1}
                                            onChange={handleChangeProducto("TarNov", k)}
                                        />}
                                    />
                                </Fragment>
                                <br />
                                <Typography variant="gris13">
                                    {t("MisProductos.PrecioSinIva")}:
                                </Typography>
                                <FormControlLabel sx={{ ml: 1 }}
                                    aria-label="Acknowledge"
                                    onClick={event => event.stopPropagation()}
                                    onFocus={event => event.stopPropagation()}
                                    control={<CajaTexto
                                        id={"TarVal" + id + m.MerCod}
                                        tipo={"number"}
                                        ancho={80}
                                        valor={m.TarVal === 'null' ? 0 : m.TarVal}
                                        accion={handleChangeProducto("TarVal", k)}
                                    />}
                                />
                                <Typography sx={{ ml: -2 }} variant="gris13" display="inline">{" € / " + arrP.uniAVer[ficha.ProUni]}</Typography>
                                {(m.TarVal > 0) &&
                                    <Fragment>
                                        <Typography sx={{ ml: 2 }} variant="gris13">
                                            {t("MisProductos.PrecioIvaIncluido")}:
                                        </Typography>
                                        <Typography sx={{ ml: 1 }} variant="gris13" >{PrecioConIva(m.TarVal, state.gruposIva, ficha.ProIva) + " € / " + arrP.uniAVer[ficha.ProUni]}</Typography>
                                    </Fragment>
                                }
                                <br />
                                {ficha.ProFac !== "" && ficha.ProFac !== "0" &&
                                    ficha.ProFac !== "1" && m.TarVal > 0 &&
                                    < Fragment >
                                        {state.idioma === "Es" &&
                                            <Typography sx={{ ml: 0 }} variant="gris13">
                                                {t("MisProductos.ElProductoLeSaleA")}:
                                            </Typography>}
                                        <Typography sx={{ ml: 1 }} variant="gris13" >
                                            {PrecioConIva(m.TarVal / ficha.ProFac, state.gruposIva, ficha.ProIva) + " € / " +
                                                (ficha.ProUn2 !== '' ? arrP.un2AVer[ficha.ProUn2] : "")}
                                        </Typography>
                                        {state.idioma === "Eus" &&
                                            <Typography sx={{ ml: 0 }} variant="gris13">
                                                {t("MisProductos.ElProductoLeSaleA")}:
                                            </Typography>}
                                    </Fragment>
                                }
                                <br />

                            </Fragment>
                        ))}
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleCloseAbandonar}
                    >
                        {t("MisProductos.Abandonar")}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 90, height: 30 }}
                        onClick={handleCloseGuardar}
                    >
                        {t("MisProductos.Guardar")}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
export default withTranslation()(DialogProducto)