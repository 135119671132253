import React, { useState } from 'react';
import { withTranslation } from 'react-i18next'

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'
import CajaTexto from './../../components/CajaTexto.js';

const DialogBusqueda = ({ t, ...props }) => {

    const [textoBusca, setTextoBusca] = useState("")
    const handleChangeTexto = (event) => {
        setTextoBusca(event)
    }

    const handleBuscar = () => {
        props.actualizador(true, textoBusca)
    }

    const handleAbandonar = () => {
        props.actualizador(false)
    }


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Dialog
                open={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="azul13">{t("MisProductos.Buscar")}</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="gris13" >
                            {t("MisProductos.NombreProducto")}:
                        </Typography>
                        <FormControlLabel sx={{ ml: 2 }}
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={<CajaTexto
                                id={"ProDesB"}
                                foco={true}
                                tipo={"text"}
                                ancho={450}
                                valor={textoBusca}
                                accion={handleChangeTexto}
                            />}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleAbandonar}
                    >
                        {t("MisProductos.Abandonar")}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 90, height: 30 }}
                        onClick={handleBuscar}
                    >
                        {t("MisProductos.Buscar")}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
export default withTranslation()(DialogBusqueda)