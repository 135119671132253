import React, { useState } from 'react';
import Button from '@mui/material/Button';

import config from './../config/config.js'

//import { updateFotoProducto } from './../apis/api-producto.js'

const defaultImgProducto = require('./../assets/images/defectos/producto_0000.png')

export default function CambiaImagen({ ...props }) {
    const tam = "80"
    const foto = config.rutaFotosProductos + props.codigo + '.jpg?ver=' + (new Date().getTime())
    const [, setAnchorEl] = useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = event => {
        setAnchorEl(null);
    };

    const [imagen, setImagen] = useState(foto)

    const handleFileUpload = async (event) => {
        const value = event.target.files[0]
        //const newName = props.codigo + '.jpg'

        if (typeof value === 'undefined') return;
        if (value.size > 10 * 1024 * 1024) {
            alert("¡El fichero supera el máximo tamaño permitido (10 Mb)!");
            return
        }

        // Cambiar la foto actual por la seleccionada. //
        const foto2 = new FileReader();
        foto2.readAsDataURL(value)
        foto2.onload = async function () {
            // Esto se puede hacer si se sube correctamente. //
            setImagen(foto2.result);
            props.func(foto2.result)
        }

        /*
             // Pasar a api-productos.js
             await updateFotoProducto({image: foto2.result, name: newName}).then((response) => {
                 if (response.ok) {
                     alert("Fichero cargado correctamente");
                 }
                 else {
                     alert("Errores en la carga del fichero");
                 }
             })
         */
    }



    return (
        <Button variant="contained" component="label" color="blanco" >
            {imagen &&
                <img
                    width={tam}
                    height={tam}
                    //src={foto}
                    src={imagen}
                    onError={(event) => event.target.setAttribute("src", defaultImgProducto)}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    alt="Producto"
                />
            }
            <input id="image"
                hidden
                accept=".jpg"
                type="file"
                name="image"
                onChange={handleFileUpload}
            />
        </Button>
    )
}