import React, { /*Fragment,*/ useState, useEffect } from "react";
import { useStore } from 'react-create-use-store';
import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";

import Container from '@mui/material/Container';

import Typography from '@mui/material/Typography';

import { ThemeProvider } from '@mui/material/styles';
//import Grid from "@mui/material/Grid";


import store from './adderStore.js'
import ThemeGeroa from './ThemeGeroa.js'

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CajaTexto from './components/CajaTexto.js';
//import Select from "@mui/material/Select";
//import FormControl from "@mui/material/FormControl";
//import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

//import { enviaEmailCliente } from './apis/api-email.js'


const CambioPrCo = ({ t, ...props }) => {

    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)

    const { state, actions } = useStore(store);


    const [estado, /*setEstado*/] = useState(
        {
            carga: true
        }
    )
    const [ficha, setFicha] = useState({ codigo: state.codProveedor, tipo: state.esProductor, mail: "armentia@geroa.com" })



    useEffect(() => {
        if (estado.carga) {
            ficha.codigo = state.codProveedor
            ficha.tipo = state.esProductor
            ficha.mail = state.emailUsuario
            //
            setFicha({ ...ficha })
            estado.carga = false
        }
    })




    const handleCloseGuardar = event => {
        console.log("Guardar = ", ficha)
        actions.cambiarEsProductor(ficha.tipo)
        actions.cambiarCodProveedor(ficha.codigo)
        actions.cambiarEmailUsuario(ficha.mail)

    }


    const handleChangeProducto = event => {
        ficha.codigo = event
        setFicha({ ...ficha })
    }


    const handleChangeTipo = event => {
        ficha.tipo = event.target.checked
        setFicha({ ...ficha })
    }


    const handleChangeMail = event => {
        ficha.mail = event
        setFicha({ ...ficha })
    }

/*
    const handleEmail = event => {
        console.log('ssss')
        //
        enviaEmailCliente('armentia@geroa.com', "Compra en Sareko", 'cadena').then((data) => {
            let error = ''
            if (data) {
                // Error
                if (data.rejected.length > 0) {
                    data.rejectedErrors.map(rechazo => {
                        error = "Envio Rechazado: " + rechazo.recipient + "  Motivo: " + rechazo.response
                        // Return del map
                        return null
                    })
                }
            }
            if (error === '') {
                // Salta a pantalla Final - Email enviado
                console.log('Envio OK')
            }
            else {
                console.log('Envio Incorrecto: ', error)
            }
        })
    }
*/

console.log('Al entrar en cambio = ', state)

    //} else if (campo === "TarVis") {
    //ficha.Mercados[m].TarVis = event.target.checked ? 1 : 0


    const tituloYFiltros = (
        <div>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 4 / 6 }}>
                    <Typography variant="verde30">
                        {"Cambio de Operativa Proveedor / Coordinador"}
                    </Typography>


                </Box>



            </Box>

            <Box sx={{ mt: 4 }}>

                <Typography variant="gris15" >
                    {"•	Un productor de un mercado monoproductor: COD: 41000028 (Larreta)"}
                </Typography>
                <br />
                <Typography variant="gris15" >
                    {"•	Un productor de un mercado multiproductor: 41000005 (Maria Begoña Villar)"}
                </Typography>
                <br />
                <Typography variant="gris15" >
                    {"•	1 Coordinador de un mercado que sea multiproductor: 412000007 (Etartiedendakor)"}
                </Typography>
                <br />
                <br />

                <Typography variant="gris13" >
                    {"Producto/Coordinador"}:
                </Typography>
                <FormControlLabel sx={{ ml: 2 }}
                    aria-label="Acknowledge"
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={<CajaTexto
                        id={"ProCod"}
                        tipo={"text"}
                        ancho={450}
                        valor={ficha.codigo}
                        accion={handleChangeProducto}
                    />}
                />

                <Typography sx={{ ml: 5 }} variant="gris13" display="inline">
                    {"Es Productor"}:
                </Typography>
                <FormControlLabel
                    sx={{ ml: 0.5 }}
                    aria-label="Acknowledge"
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={<Checkbox
                        id={"MerVis"}
                        checked={ficha.tipo}
                        onChange={handleChangeTipo}
                    />}
                />
                <br />
                <Typography variant="gris13" >
                    {"Mail"}:
                </Typography>
                <FormControlLabel sx={{ ml: 2 }}
                    aria-label="Acknowledge"
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={<CajaTexto
                        id={"ProMai"}
                        tipo={"text"}
                        ancho={450}
                        valor={ficha.mail}
                        accion={handleChangeMail}
                    />}
                />

                <Button
                    variant="contained"
                    sx={{ ml: 5, mb: 1, mr: 2, width: 90, height: 30 }}
                    onClick={handleCloseGuardar}
                >
                    {t("MisProductos.Guardar")}
                </Button>

            </Box>
            <br />
            <br />
            <br />
          


        </div>
    )






    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}

                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(CambioPrCo)

