import React, { Fragment, useState, useEffect } from "react";

import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid';



import ThemeGeroa from './../../ThemeGeroa.js'
//import auth from './../../apis/auth-helper.js'
import { listaFacDesProvCoor } from './../../apis/api-factura.js'

import MensajeCabecera from "../../components/MensajeCabecera.js";
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'
import Box from "@mui/material/Box";

import { FechaDdMmAAAA, FechaAaaaMmDd, RedondeaPrecio } from "../../config/funcionesPropias.js";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Lista from "../../components/Lista.js";


import 'react-toastify/dist/ReactToastify.css';


import BotonFecha from "../../components/BotonFecha.js";
//import BotonPdf from "../../components/BotonPdf.js";

import BotonXlsx from "../../components/BotonXlsx.js";
//import config from './../../config/config.js'

const FacDesglosadas = ({ t, ...props }) => {

    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)

    const { state, actions } = useStore(store);




    var date = new Date()
    // Prepara las posibilidades de las capturas de Mercados
    var listaMercados = []
    for (let i = 0; i < state.mercadosProveedor.length; i++) {
        if (i === 0) {
            listaMercados.push(t("MisInformes.Todos"))
        }
        listaMercados.push(
            state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
        )
    }
    const [estado, setEstado] = useState(
        {
            carga: true,
            fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
            fechaFin: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            mercado: 1,
            seleccionMercados: listaMercados,
            lineasFacturas: [],
            lineasExcel: []
        }
    )



    useEffect(() => {
        if (estado.carga) {
            loadLineasFacturasIniciales()
            // Actualiza el estado sin Repintar. //
            estado.carga = false
        }
    })



    function actualizadorFechaDesde(fecha) {
        estado.fechaInicio = fecha
        setEstado({ ...estado })
        loadLineasFacturasIniciales()
    }

    function actualizadorFechaHasta(fecha) {
        estado.fechaFin = fecha
        setEstado({ ...estado })
        loadLineasFacturasIniciales()
    }


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        loadLineasFacturasIniciales()
    }


    // Contar los productos para establecer el calendario
    async function loadLineasFacturasIniciales() {
        if (estado.lineasFacturas.length > 0) {
            setEstado({ ...estado, lineasFacturas: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        let mercadosFiltro
        if (state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod
            await listaFacDesProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", mercado: mercadosFiltro, diaDesde: FechaAaaaMmDd(estado.fechaInicio), diaHasta: FechaAaaaMmDd(estado.fechaFin) }).then((data) => {
                //console.log('datos = ', data)
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    preparaVisualizacionYExcel(data)
                }
            })
        }
    }



    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacionYExcel(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisFacturas.Numero"),
                t("MisFacturas.Fecha"),
                t("MisFacturas.Mercado"),
                t("MisFacturas.Productor"),
                t("MisFacturas.Cliente"),
                t("MisFacturas.Producto"),
                t("MisFacturas.Cantidad"),
                t("MisFacturas.Precio"),
                t("MisFacturas.Importe")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                resultado.push({
                    Numero: data[i].FacNum,
                    Fecha: FechaDdMmAAAA(new Date(data[i].FacFec)),
                    Mercado: state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE,
                    Productor: data[i].UsuRazonSocial,
                    Cliente: data[i].CliNom,
                    Producto: (state.idioma === "Es" ? data[i].ProDesC : data[i].ProDesE) + " (" + data[i].ProCod + ")",
                    Cantidad: data[i].LinSer,
                    Precio: data[i].LinPre,
                    Importe: Number(data[i].LinSer) * Number(data[i].LinPre),
                    Iva1: Number(data[i].Lin)
                })
                // Añadir objeto al Array. //
                resultadoExcel.push({})
                // Guardar datos en objeto (Nombres Eus-Esp) //
                resultadoExcel[i][cabeceraExcel[0]] = data[i].FacNum
                resultadoExcel[i][cabeceraExcel[1]] = FechaDdMmAAAA(new Date(data[i].FacFec))
                resultadoExcel[i][cabeceraExcel[2]] = state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE
                resultadoExcel[i][cabeceraExcel[3]] = data[i].UsuRazonSocial
                resultadoExcel[i][cabeceraExcel[4]] = data[i].CliNom
                resultadoExcel[i][cabeceraExcel[5]] = (state.idioma === "Es" ? data[i].ProDesC : data[i].ProDesE) + " (" + data[i].ProCod + ")"
                resultadoExcel[i][cabeceraExcel[6]] = data[i].LinSer
                resultadoExcel[i][cabeceraExcel[7]] = data[i].LinPre
                resultadoExcel[i][cabeceraExcel[8]] = Number(data[i].LinSer) * Number(data[i].LinPre)
            }
            estado.lineasFacturas = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }



    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisFacturas.FacturasDesglosadas")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 5 / 6, display: 'flex', /*justifyContent: 'center'*/ }}>
                    <Box>
                        <BotonFecha
                            //margenI={5}
                            nombre={t("MisInformes.Desde")}
                            fecha={FechaDdMmAAAA(estado.fechaInicio)}
                            actualizadorFecha={actualizadorFechaDesde}
                        />
                    </Box>
                    <Box>
                        <BotonFecha
                            margenI={5}
                            nombre={t("MisInformes.Hasta")}
                            fecha={FechaDdMmAAAA(estado.fechaFin)}
                            actualizadorFecha={actualizadorFechaHasta}
                        />
                    </Box>
                </Box>

                <Box sx={{ ml: 3 }}>
                    <BotonXlsx data={estado.lineasExcel} filename={t("MisFacturas.Desglosadas")} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">

                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    <Lista
                        elementos={estado.seleccionMercados}
                        accion={handleFiltroMercado}
                        activos={estado.mercado}
                    />

                </Box>

            </Box>

        </Fragment>
    )



    const listaFacturasDesglosadasListado = (
        estado.lineasFacturas.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>

                            <TableRow>
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Numero")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Fecha")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Mercado")}</Typography></TableCell>
                                {!state.esProductor && <TableCell sx={{ width: 200 }} ><Typography variant="azul15">{t("MisFacturas.Productor")}</Typography></TableCell>}
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Cliente")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisFacturas.Producto")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisFacturas.Cantidad")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisFacturas.Precio")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisFacturas.Importe")}</Typography></TableCell>
                            </TableRow>

                        </TableHead>
                        <TableBody>


                            {estado.lineasFacturas.map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{l.Numero}</TableCell>
                                    <TableCell>{l.Fecha}</TableCell>
                                    <TableCell>{l.Mercado}</TableCell>
                                    {!state.esProductor && <TableCell>{l.Productor}</TableCell>}
                                    <TableCell>{l.Cliente}</TableCell>
                                    <TableCell>{l.Producto}</TableCell>
                                    <TableCell align="right"><Typography variant={"gris13"}> {RedondeaPrecio(l.Cantidad, 2)}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant={"gris13"}> {RedondeaPrecio(l.Precio, 2)}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant={"gris13"}> {RedondeaPrecio(l.Importe)}</Typography></TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>


            </Grid>
        )
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaFacturasDesglosadasListado}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(FacDesglosadas)