//import liraries
import React from 'react';
//import Button from "@mui/material/Button";
import { ThemeProvider } from '@mui/material/styles';
import { withTranslation } from 'react-i18next'
import ThemeGeroa from '../ThemeGeroa';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


// create a component
const BotonPdf = ({ t, ...props }) => {

    //const data = props.data
    //const name = props.filename
    //const desabilitado = props.desabilitado

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <a
                href={props.fichero}
                target="_blank"
                rel="noopener noreferrer"
                download
            >
                <PictureAsPdfIcon style={{ color: ThemeGeroa.palette.primary.main, fontSize: 30 }} />
            </a>
        </ThemeProvider>
    );
};

export default withTranslation()(BotonPdf)