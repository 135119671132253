import React, { useState, useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { useStore } from 'react-create-use-store';

import { ThemeProvider, alpha, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
//import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Select from "@mui/material/Select";

import SearchIcon from '@mui/icons-material/Search';

import { BuscaIndice, FechaAaaaMmDd, PrecioLineaConIva, RedondeaPrecio, StrZero } from '../../config/funcionesPropias.js'
import ThemeGeroa from './../../ThemeGeroa.js'
import store from './../../adderStore.js'
import CajaTexto from './../../components/CajaTexto.js';

import { listaProductosProveedor } from './../../apis/api-producto.js'
import { listaFPagoMercados } from '../../apis/api-mercado.js';
import { listaClientesMercado } from './../../apis/api-cliente.js'
import { crearNuevoPedido } from '../../apis/api-pedido.js';


const DialogAñadirPedido = ({ t, ...props }) => {

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
            fontSize: 13,
            width: 'auto',
            padding: '2px 2px 2px 14px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const { state, /*actions*/ } = useStore(store);
    const codigoMercado = props.m
    const fechaEntrega = new Date(props.f)
    const mercado = state.mercadosProveedor.filter((m) => {
        return (m.MerCod === codigoMercado)
    })
    const nombreMercado = mercado.length > 0 ? (state.idioma === "Es" ? mercado[0]?.MerDesC : mercado[0]?.MerDesE) : "Mercado no encontrado"

    const [estado, setEstado] = useState(
        {
            carga: true,
            mensaje: "Cargando productos...",
            //verDialogo: true,
            buscar: false,
            busCli: "",
            cliente: {},
            clientes: [],
            productos: [],
            fPago: "",
            FPagoM: []
        }
    )

    useEffect(() => {
        if (estado.carga) {
            loadProductos()
            estado.carga = false
            estado.mensaje = ""
        }
    })

    async function loadProductos() {
        // Cargar las formas de pago del mercado. //
        await listaFPagoMercados({ mercado: codigoMercado }).then((data) => {
            if (data.error) {
                console.log("Error:", data)
            }
            else {
                // Dependiendo de las formas de Pago. //
                if (data.length > 0) {
                    estado.fPago = data[0].ForTip
                    estado.FPagoM = data
                }
            }
        })
        // Busca los productos en funcion del filtro:
        await listaProductosProveedor({ proveedor: state.codProveedor, filtro: 1, desde: 0, cuantos: 10000, idioma: state.idioma }, { texto: "" }).then((data) => {
            if (data.error) {
                console.log("Error:", data)
            }
            else {
                // Filtramos los productos del mercado
                let productosMercado = data.filter((p) => {
                    let ind = BuscaIndice(p.Mercados, "MerCod", codigoMercado)
                    if (ind === -1) return false
                    if (p.Mercados[ind].TarVis !== 1) return false
                    return true
                })
                //
                let datos = []
                productosMercado.forEach((paa) => {
                    let tarifa = paa.Mercados.filter(m => m.MerCod === codigoMercado)
                    if (tarifa.length > 0) {
                        let precio = tarifa[0].TarVal
                        datos.push({
                            //LinNum: pedido,
                            LinArt: paa.ProCod,
                            LinLot: paa.ProLot,
                            //LinDes: paa.ProDesC,
                            LinDes: state.idioma === "Es" ? paa.ProDesC : paa.ProDesE,
                            LinPro: state.codProveedor,
                            LinCan: "",
                            LinEnt: "",
                            LinSer: "",
                            LinPre: precio,
                            LinIva: paa.ProIva,
                            //LinEst: "S",
                            LinInc: "",
                            LinExi: 0,
                            ProExi: Number(paa.ProExi),
                            ProEIn: Number(paa.ProEIn)
                        })
                    }
                })
                estado.productos = datos
                // Forma el Array eliminando los productos que ya existen y añadiendo los campos que necesitamos
                // Fuerza Renderizado
                setEstado({ ...estado })
            }
        })
    }

    const handleAbandonar = () => {
        props.actualizador(false)
    }

    const handleGuardar = () => {
        let cli = estado.cliente
        let lineas = estado.productos.filter((p) => p.LinCan > 0)
        // Poner a Todas las lineas las Existencias a descontar. //
        lineas.forEach(l => {
            // Si hay control de Existencias. //
            if (l.ProEIn > 0) {
                l.LinExi = Number(l.LinCan)
            }
        })
        if (cli.CliCod !== undefined && lineas.length > 0) {
            setEstado({ ...estado, mensaje: "Registrando..." })
            // Convertir a Cadena AAAA-MM-DD //
            let hoy = new Date()
            hoy = StrZero(hoy.getFullYear(), 4) + "-" +
                StrZero((hoy.getMonth() + 1), 2) + "-" +
                StrZero(hoy.getDate(), 2) + " " +
                StrZero(hoy.getHours(), 2) + ":" +
                StrZero(hoy.getMinutes(), 2) // +":"
            //StrZero(fec.getSeconds(), 2)
            // Poner como fecha de Entrega la del calendario. //
            let fEn = FechaAaaaMmDd(fechaEntrega) + " 12:00"
            let pedido = {
                PedFec: hoy,
                PedCli: cli.CliCod,
                PedMer: codigoMercado,
                PedFPa: estado.fPago,
                PedFEn: fEn,
                PedFFa: "",
                PedREx: "1",
                PedPDF: "0",
                PedTEn: "D",
                PedEma: cli.CliEma,
                PedRaz: cli.CliNom,
                PedCal: cli.DirCal,
                PedPob: cli.DirPob,
                PedCpo: cli.DirCpo,
                PedPro: cli.DirPro,
                PedTel: cli.CliTel,
                PedRep: "",
                PedRNo: "",
                PedREm: "",
                productos: lineas
            }
            crearNuevoPedido(pedido).then((data) => {
                if (data.error) {
                    // Se podría dar un mensaje por si falla. //
                    console.log("Error:", data)
                }
                else {
                    // Ha creado el pedido. //
                    props.actualizador(true)
                }
            })
        }
        // Salirse
    }


    const handleChangePedido = (campo, i) => event => {
        let est = estado
        //
        if (campo === "Cantidad") {
            est.productos[i].LinCan = event
        } else if (campo === "BusCli") {
            // Si teclea no busca. //
            est.buscar = false
            est.busCli = event
        } else if (campo === "SelCli") {
            // Localizar en el array el elemento seleccionado //
            let sel = est.clientes.filter((c) => {
                return (c.DirCod + c.DirNum === event.target.value)
            })
            // guardar todos los datos del cliente. //
            est.cliente = sel.length > 0 ? sel[0] : {}
        } else if (campo === "SelFor") {
            // guardar la forma de pago del mercado. //
            est.fPago = event.target.value
        }
        //
        setEstado({ ...est })
    }

    const handleBuscarCliente = async event => {
        if (estado.busCli !== "") {
            await listaClientesMercado({ mercado: codigoMercado }, { texto: estado.busCli }).then((data) => {
                if (data.error) {
                    console.log("Error:", data)
                }
                else {
                    //console.log('Clientes = ', data)
                    // Actualizar estado. //
                    estado.buscar = true
                    // Poner el primer cliente. //
                    estado.cliente = data.length > 0 ? data[0] : ""
                    estado.clientes = data
                    // Fuerza Renderizado
                    setEstado({ ...estado })
                }
            })
        }
    }

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Dialog
                //open={estado.verDialogo}
                open={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="azul15">
                        {t("MisPedidos.AñadirPedido") + " " + nombreMercado}
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography variant="gris13" >
                        {t("MisPedidos.Cliente")}:
                    </Typography>
                    <FormControlLabel sx={{ ml: 1 }}
                        aria-label="Acknowledge"
                        onClick={event => event.stopPropagation()}
                        onFocus={event => event.stopPropagation()}
                        control={<CajaTexto
                            id={"BusCli"}
                            foco={true}
                            tipo={"text"}
                            ancho={180}
                            valor={estado.busCli}
                            accion={handleChangePedido("BusCli")}
                        />}
                    />
                    <Button
                        variant="contained"
                        startIcon={<SearchIcon />}
                        sx={{ mt: 0, height: 25, bgcolor: ThemeGeroa.palette.azul.main, color: ThemeGeroa.palette.blanco.main }}
                        onClick={handleBuscarCliente}
                    />
                    {estado.clientes.length > 0 ?

                        <FormControl
                            sx={{ ml: 1, mt: 0, mb: 3 }}
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                        >
                            <Select
                                id={"Clientes"}
                                //autoFocus
                                value={estado.cliente.DirCod + estado.cliente.DirNum}
                                //onChange={handleFiltroIvas}
                                onChange={handleChangePedido("SelCli")}
                                input={<BootstrapInput id={"SelCli"} />}
                            >
                                {estado.clientes.map((c, i) => (
                                        <MenuItem value={c.DirCod + c.DirNum} key={c.DirCod + c.DirNum}> {c.DirCod + " " + c.DirNom + " (" + c.DirCal + " - " +c.DirPob + ")"} </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        :
                        <Typography variant="rojo13" sx={{ ml: 1 }} >
                            {estado.buscar ? t("MisPedidos.NoEncontrado") : t("MisPedidos.SeleccionarCliente")}
                        </Typography>
                    }
                    {estado.FPagoM.length > 1 &&
                        <Fragment>
                            <br sx={{ mt: 1 }} />
                            <Typography variant="gris13" >
                                {t("MisPedidos.FormaPago")}:
                            </Typography>
                            <FormControl
                                sx={{ ml: 1 }}
                                onClick={event => event.stopPropagation()}
                                onFocus={event => event.stopPropagation()}
                            >
                                <Select
                                    id={"FormasPago"}
                                    //autoFocus
                                    value={estado.fPago}
                                    onChange={handleChangePedido("SelFor")}
                                    input={<BootstrapInput id={"SelFor"} />}
                                >
                                    {estado.FPagoM.map((f, i) => (
                                        <MenuItem value={f.ForTip} key={f.ForMer + f.ForTip}>
                                            {f.ForTip + " " + (state.idioma === "Es" ? f.ForDesC : f.ForDesE)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Fragment>
                    }
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: 80 }}>{t("MisPedidos.Cantidad")}</TableCell>
                                    <TableCell>{t("MisPedidos.Producto")}</TableCell>
                                    <TableCell sx={{ width: 120 }} align="right">{t("MisPedidos.PrecioPrecioIva")}</TableCell>
                                    <TableCell align="right">{t("MisPedidos.Existencias")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {estado.productos.map((p, i) => (
                                    <TableRow
                                        key={"Prod" + p.ProCod + i}
                                        sx={{
                                            '&:last-child td, &:last-child th': {
                                                border: 0,
                                                paddingTop: 1, paddingBottom: 1
                                            }
                                        }}
                                    >
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }}>
                                            <FormControlLabel
                                                sx={{ width: 40 }}
                                                aria-label="Acknowledge"
                                                onClick={event => event.stopPropagation()}
                                                onFocus={event => event.stopPropagation()}
                                                control={<CajaTexto
                                                    id={"ProCan" + p.LinArt + p.LinCan + i}
                                                    tipo={"number"}
                                                    ancho={60}
                                                    valor={p.LinCan}
                                                    accion={handleChangePedido("Cantidad", i)}
                                                />}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} component="th" scope="row">
                                            {p.LinDes}{" (" + p.LinArt + ")"}
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} align="right">{RedondeaPrecio(p.LinPre)} {" (" + PrecioLineaConIva(p.LinPre, state.gruposIva, p.LinIva, 1) + ")"}</TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} align="right">{p.ProExi}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <Typography variant="rojo16">
                        {estado.mensaje}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleAbandonar}
                    >
                        {t("MisProductos.Abandonar")}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 90, height: 30 }}
                        onClick={handleGuardar}
                    >
                        {t("MisProductos.Guardar")}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default withTranslation()(DialogAñadirPedido)