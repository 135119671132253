import React, { Suspense, useEffect, useState, /*useLayoutEffect*/ } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

// Obligatorio: No comentar. //
import i18n from './i18n';
import { withTranslation } from 'react-i18next'

//import MensajeCabecera from "./components/MensajeCabecera.js";
//
import Rutas from "./routes/rutas.js";
//
import ThemeGeroa from './ThemeGeroa.js'
import Cabecera from './core/cabecera.js'
//import Footer from './core/footer.js'

import { listaIvas } from "./apis/api-iva.js"
import { listaFamilias } from "./apis/api-familia.js";
import { listaMercadosProvCoor } from "./apis/api-mercado.js";
//import { borrarFamiliasPuntosFecha } from "./apis/api-punto.js";

import { FechaAaaaMmDd } from './config/funcionesPropias.js'

import { useStore } from 'react-create-use-store';
import store from './adderStore.js'

let pruebas = false

function App({ t }) {

  const [carga, setCarga] = useState(true)
  const { state, actions } = useStore(store);

  /////////////////////////////////////////////////
  // Variables Globales y Funciones para manejarlas
  /////////////////////////////////////////////////
  // leer el Idioma último usado
  let idio = localStorage.getItem('idioma')
  idio = idio === null ? 'Eus' : idio
  //

  function loadUnis() {
    // Lista de Unidades de los Productos
    const uniAVer = [t("MisProductos.Ud"),
    t("MisProductos.Kg"),
    t("MisProductos.Lit")
    ]
    actions.cambiarUniAVer(uniAVer)
    const un2AVer = [t("MisProductos.Ud"),
    t("MisProductos.Kg"),
    t("MisProductos.Lit")
    ]
    actions.cambiarUni2AVer(un2AVer)
  }


  function loadTiposPuntos() {
    const tiposPuntos = [t("MisMapas.Tipo0"),
    t("MisMapas.Tipo1"),
    t("MisMapas.Tipo2"),
    t("MisMapas.Tipo3"),
    t("MisMapas.Tipo4"),
    t("MisMapas.Tipo5"),
    t("MisMapas.Tipo6"),
    t("MisMapas.Tipo7"),
    t("MisMapas.Tipo8"),
    t("MisMapas.Tipo9"),
    t("MisMapas.Tipo10")
    ]
    actions.cambiarTiposDePuntos(tiposPuntos)
  }


  /////////////////////////////////
  /////////////////////////////////
  // TODOS LOS GRUPOS DE IVA
  /////////////////////////////////
  /////////////////////////////////
  async function loadIvas() {
    await listaIvas().then((dataI) => {
      if (dataI.error) {
        console.log(dataI.error)
      }
      else {
        actions.cambiarGruposIva(dataI)
        //
      }
    })
  }

  /////////////////////////////////
  /////////////////////////////////
  // TODAS LAS FAMILIAS
  /////////////////////////////////
  /////////////////////////////////
  async function loadFamilias() {
    await listaFamilias().then((dataF) => {
      if (dataF.error) {
        console.log(dataF.error)
      }
      else {
        actions.cambiarFamilias(dataF)
        //
      }
    })
  }

  /////////////////////////////////
  /////////////////////////////////
  // MERCADOS DEL PRODUCTOR
  /////////////////////////////////
  /////////////////////////////////
  async function loadMercados(pro) {
    await listaMercadosProvCoor({ esProductor: state.esProductor ? "1" : "0", proveedor: pro }).then((dataM) => {
      if (dataM.error) {
        console.log(dataM.error)
      }
      else {
        actions.cambiarMercadosProveedor(dataM)
        //
      }
    })
  }

  /////////////////////////////////
  /////////////////////////////////
  // BORRA FAMILIAS PUNTOS
  /////////////////////////////////
  /////////////////////////////////
  async function borraFamiliasPuntos() {
    if (state.codProveedor === '') {
      await listaMercadosProvCoor({ fecha: FechaAaaaMmDd(new Date()) }).then((data) => {
        if (data.error) {
          console.log(data.error)
        }
      })
    }
  }


  useEffect(() => {
    if (carga) {
      setCarga(false)
      // Si el idioma actual es distinto del almacenado. //
      if (idio !== state.idioma) {
        actions.cambiarIdioma(idio)
      }
      //
      if (pruebas) {
        let pro = "41000028"
        actions.cambiarIdioma(idio)
        actions.cambiarEmailUsuario("armentia111@geroa.com")
        actions.cambiarEsProductor(true)
        actions.cambiarCodProveedor(pro)
        //
        loadMercados(pro)
      }

      loadIvas()
      loadUnis()
      loadFamilias()
      loadTiposPuntos()
      borraFamiliasPuntos()
    }
  }, [])


  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={ThemeGeroa}>
        <BrowserRouter>
          <Cabecera />
          <Rutas />
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  )
}

export default withTranslation()(App)