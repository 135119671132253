import React from "react";
import { withTranslation } from 'react-i18next'
import { alpha, styled } from '@mui/material/styles';

//import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/InputBase';

//import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
//import FormHelperText from '@mui/material/FormHelperText';

import Select from '@mui/material/Select';



const Lista = ({ t, ...props }) => {

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
      fontSize: 13,
      width: 'auto',
      padding: '2px 2px 2px 14px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));


  return (
    <FormControl
      sx={{ ml: 1, mt: -0.5, mb: 5 }}
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
    >
      <Select
        value={props.activos}
        onChange={props.accion}
        input={<BootstrapInput id={"Input" + props.id} />}
      >
        {props.elementos.map((ele, index) => <MenuItem value={index} key={index}>{ele}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default withTranslation()(Lista)