//import liraries
import React from 'react';
import Button from "@mui/material/Button";

import { withTranslation } from 'react-i18next'

const ImgExcel = require('./../assets/images/logos/excel.png')

// create a component
const BotonXlsx = ({ t, ...props }) => {

    var XLSX = require('xlsx');
    var fileSaver = require('file-saver');

    const data = props.data
    const name = props.filename
    const desabilitado = props.desabilitado

    var saver = function (_a) {
        var name = _a.name, file = _a.file, type = _a.type, extension = _a.extension;
        var data = new Blob([file], { type: type });
        fileSaver.saveAs(data, name + extension);
    };


    var exportExcel = () => {
        //var data = _a.data, name = _a.name;
        var fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        var fileExtension = ".xlsx";
        var ws = XLSX.utils.json_to_sheet(data);
        var wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        var excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        saver({ name: name, file: excelBuffer, type: fileType, extension: fileExtension });
    };



    return (
            <Button
                sx={{ mt:-1, width: 130, height: 30 }}
                disabled = {desabilitado}
                startIcon={<img width={30} height={30} src={ImgExcel} alt="ficheroExcel" />}
                variant="contained"
                onClick={exportExcel}
            >
                {"Excel"}
            </Button >

    );
};
export default withTranslation()(BotonXlsx)
