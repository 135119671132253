import React, { useState, /*useLayoutEffect, Fragment*/ } from "react";

import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Grid from "@mui/material/Grid";
//import ImageList from "@mui/material/ImageList";

import { ThemeProvider } from '@mui/material/styles';

//import { useNavigate, Redirect } from "react-router-dom"
//import { useStore } from 'react-create-use-store';
//import store from './../adderStore.js'


import ThemeGeroa from './../ThemeGeroa.js'


import { withTranslation } from 'react-i18next'
import { Button } from "@mui/material";
import imgBoton from './../assets/images/logos/merkaturatzeSarea.png'



const Entrada = ({ t, ...props }) => {

    //const { state, actions } = useStore(store);

    const [errorDinahosting, /*setErrorDinahosting*/] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }


    // Por si hay error de Conexion a Dinahosting
    const errorConexionDinahosting = (
        errorDinahosting ?
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle variant="h2" id="alert-dialog-title">
                    {t("General.Advertencia")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText variant="h4" id="alert-dialog-description">
                        {t("Mercados.TextoError")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseDialog} autoFocus>
                        {t("Aceptar")}
                    </Button>
                </DialogActions>
            </Dialog>
            :
            <div />
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            {errorConexionDinahosting}
            <Container sx={{ height: window.innerHeight - ThemeGeroa.heightFooter - ThemeGeroa.heightCabecera }}>
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ height: window.innerHeight - ThemeGeroa.heightFooter - ThemeGeroa.heightCabecera }}>
                    <img src={imgBoton} width={window.innerHeight - 100} alt="merkaturatzeSarea"/>
                </Grid>
            </Container>
        </ThemeProvider >
    );
}

export default withTranslation()(Entrada)