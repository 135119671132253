import React, { useState, useEffect } from "react";
import { useStore } from 'react-create-use-store';
import { withTranslation } from 'react-i18next';

import { countLinPedMesProvCoor } from './../../apis/api-pedido.js';
import Badge from '@mui/material/Badge';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

import CircleIcon from '@mui/icons-material/Circle';

import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';
import './MiCalendario.css';

//import auth from './../../apis/auth-helper.js';
import ThemeGeroa from "./../../ThemeGeroa.js";
import store from './../../adderStore.js';
import { BuscaTrozoIndice, FechaAaaaMmDd, StrZero } from "../../config/funcionesPropias.js";


const forma1 = { bgcolor: 'red', width: 12, height: 1 };
const forma2 = { bgcolor: 'green', width: 12, height: 1 };
const forma3 = { bgcolor: 'red', width: 12, height: 1 };
const rectangle1 = <Box component="span" sx={{ ...forma1 }} />;
const rectangle2 = <Box component="span" sx={{ ...forma2 }} />;
const rectangle3 = <Box component="span" sx={{ ...forma3 }} />;


const MiCalendario = ({ t, ...props }) => {

    //const jwt = auth.isAuthenticated()
    const { state, /*actions*/ } = useStore(store);

    const f = props.estados.dia
    const ano = f.substr(0, 4)
    const mes = f.substr(5, 2) - 1
    const dia = f.substr(8, 2)

    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    // Estados variables y funciones relacionadas con el Calendario
    const [estadoCalendarioMes, setEstadoCalendarioMes] = useState(
        {
            carga: true,
            //aaaamm: new Date().getFullYear() + "-" + StrZero(new Date().getMonth() + 1, 2),
            aaaamm: props.estados.dia.substr(0, 7),
            pedidosMes: []
        }
    )

    const [date, /*setDate*/] = useState(new Date(ano, mes, dia));
    const handleSeleccionaDia = event => {
        // No permitir saltar si selecciona fuera del mes en el que estamos, o si no hay pedidos ese dia
        let mesDeLaSeleccion = FechaAaaaMmDd(event).substr(5, 2)
        if (mesDeLaSeleccion === estadoCalendarioMes.aaaamm.substr(5, 2)) {
            let indiceEncontrado = BuscaTrozoIndice(estadoCalendarioMes.pedidosMes, "fecha", 0, 10, FechaAaaaMmDd(event))
            if (indiceEncontrado !== -1) {
                // Salta a buscar
                let est = props.estados
                est.verCalendario = false
                est.dia = FechaAaaaMmDd(event)
                est.carga = true
                props.funcionEstados({ ...est })
            }
        }
    }

    const handleCambiaMesAño = event => {
        let nuevoMes = event.activeStartDate.getFullYear() + "-" + StrZero(event.activeStartDate.getMonth() + 1, 2)
        let est = estadoCalendarioMes
        est.aaaamm = nuevoMes
        est.carga = true
        setEstadoCalendarioMes({ ...est })
        // Al cambiar el Mes o el Año no hace falta cambiar la fecha.
        //setDate()
    }

    useEffect(() => {
        if (estadoCalendarioMes.carga) {
            loadPedidosInicialesMes()
            estadoCalendarioMes.carga = false
        }
    })


    // Contar los productos para establecer el calendario
    async function loadPedidosInicialesMes() {
        //console.log('antes = ', state.codProveedor, ' - ', state.esProductor ? "1" : "0", ' mes - ', estadoCalendarioMes.aaaamm )
        await countLinPedMesProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", mes: estadoCalendarioMes.aaaamm }).then((data) => {
            if (data.error) {
                console.log("Error", data)
            }
            else {
                let fecha = "", pedido = ""
                let dias = []
                data.forEach((l) => {
                    if (fecha !== l.PedFEn.substr(0, 10)) {
                        fecha = l.PedFEn.substr(0, 10)
                        pedido = ""
                        dias.push({
                            "fecha": l.PedFEn,
                            "c1": 0,
                            "c2": 0,
                            "c3": 0
                        })
                    }
                    if (pedido !== l.PedNum) {
                        pedido = l.PedNum
                        switch (l.estado) {
                            case "1_PENDIENTE":
                                dias[dias.length - 1].c1++
                                break
                            case "2_ENTREGADO":
                                dias[dias.length - 1].c2++
                                break
                            case "3_SERVIDO":
                                dias[dias.length - 1].c3++
                                break
                            default:
                                break
                        }
                    }
                })
                let est = estadoCalendarioMes
                est.pedidosMes = dias
                setEstadoCalendarioMes({ ...est })
            }
        })
    }


    let indiceEncontrado = -1
    let canPre = 0
    let canSin = 0
    let canFac = 0

    function numPedidos(array, fecha, estado) {
        let retorno = -1
        let arr = array.filter(obj => obj.fecha.substr(0, 10) === fecha)
        if (arr.length > 0) {
            switch (estado) {
                case "1_PENDIENTE":
                    retorno = arr[0].c1
                    break
                case "2_ENTREGADO":
                    retorno = arr[0].c2
                    break
                case "3_SERVIDO":
                    retorno = arr[0].c3
                    break
                default:
                    break
            }
        }
        return retorno
    }



    return (
        <Grid>
            <CircleIcon sx={{ ml: 5, fontSize: 18, color: ThemeGeroa.palette.rojo.main }} />
            <Grid sx={{ mt: -3, ml: 8 }}>
                <Typography variant="rojo16">
                    {t("MisPedidos.Pendientes")}
                </Typography>
            </Grid>
            <CircleIcon sx={{ mt: 0.5, ml: 5, fontSize: 18, color: ThemeGeroa.palette.azul.main }} />
            <Grid sx={{ mt: -3, ml: 7 }}>
                <Typography sx={{ ml: 1 }} variant="azul16">
                    {t("MisPedidos.Preparados")}
                </Typography>
            </Grid>
            <CircleIcon sx={{ mt: 0.5, ml: 5, fontSize: 18, color: ThemeGeroa.palette.verde.main }} />
            <Grid sx={{ mt: -3, ml: 7 }}>
                <Typography sx={{ ml: 1 }} variant="verde16">
                    {t("MisPedidos.Servidos")}
                </Typography>
            </Grid>
            <Badge sx={{ ml: 10, mt: 1 }} badgeContent={"Nº"} color="gris">{" "}</Badge>
            <Grid sx={{ mt: -2, ml: 12 }}>
                <Typography variant="gris15">
                    {t("MisPedidos.Numero")}
                </Typography>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: "center" }} height="50px" width="100%">
                <Box sx={{ mt: 3 }}>
                    <Calendar
                        tileContent={({ date, view }) => {
                            if (view === "month") {
                                indiceEncontrado = BuscaTrozoIndice(estadoCalendarioMes.pedidosMes, "fecha", 0, 10, FechaAaaaMmDd(date))
                                canSin = numPedidos(estadoCalendarioMes.pedidosMes, FechaAaaaMmDd(date), "1_PENDIENTE")
                                canPre = numPedidos(estadoCalendarioMes.pedidosMes, FechaAaaaMmDd(date), "2_ENTREGADO")
                                canFac = numPedidos(estadoCalendarioMes.pedidosMes, FechaAaaaMmDd(date), "3_SERVIDO")
                                if (indiceEncontrado !== -1) {
                                    return (
                                        <Grid sx={{ ml: 0 }}>
                                            {canSin > 0 && (
                                                < Badge
                                                    sx={{ mt: 0, ml: 0, mr: 1 }}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    color="rojo"
                                                    badgeContent={canSin} >
                                                    {rectangle2}
                                                </Badge>
                                            )}
                                            {canPre > 0 && (
                                                <Badge
                                                    sx={{ mt: 0, mr: 1 }}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    color="azul"
                                                    badgeContent={canPre} >
                                                    {rectangle1}
                                                </Badge>
                                            )}
                                            {canFac > 0 && (
                                                <Badge
                                                    sx={{ mt: 0, mr: 1 }}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    color="verde"
                                                    badgeContent={canFac} >
                                                    {rectangle3}
                                                </Badge>
                                            )}
                                        </Grid>
                                    )
                                }
                            }
                        }
                        }
                        onActiveStartDateChange={handleCambiaMesAño}
                        onClickDay={handleSeleccionaDia}
                        value={date}
                    />
                </Box>
            </Box>
        </Grid >
    );
}

export default withTranslation()(MiCalendario)

/*
https://blog.logrocket.com/react-calendar-tutorial-build-customize-calendar/
*/

/*
            <Badge sx={{ mb: 0 }} color="secondary" overlap="circular" badgeContent=" " >
                {"aaaa"}
                {rectangle}
            </Badge>
*/