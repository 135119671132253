import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import BotonFecha from "./../../components/BotonFecha.js";
import BotonXlsx from "./../../components/BotonXlsx.js";
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { listaVentasClientesProvCoor } from './../../apis/api-pedido.js'

import { FechaDdMmAAAA, FechaAaaaMmDd, RedondeaPrecio, RedondeaNumero, OrdenaArray2Campos } from "./../../config/funcionesPropias.js";


const InfVentasClientes = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);

    var date = new Date()
    const [estado, setEstado] = useState(
        {
            //carga: true,
            fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
            fechaFin: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            mercado: undefined,
            seleccionMercados: [],
            lineasPedidos: [],
            lineasExcel: []
        }
    )

    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        //console.log("CargaMercados")
        if (state.mercadosProveedor.length > 0) {
            actions.cambiarMensaje("")
            // Prepara las posibilidades de las capturas de Mercados
            var listaMercados = [t("MisInformes.Todos")]
            state.mercadosProveedor.forEach(mercado => {
                listaMercados.push(state.idioma === "Es" ? mercado.MerDesC : mercado.MerDesE)
            });
            /*
            var listaMercados = []
            for (let i = 0; i < state.mercadosProveedor.length; i++) {
                if (i === 0) {
                    listaMercados.push(t("MisInformes.Todos"))
                }
                listaMercados.push(
                    state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
                )
            }
            */
            setEstado(estado => ({ ...estado, mercado: 1, seleccionMercados: listaMercados }))
        } else {
            actions.cambiarMensaje(t("FechasEntrega.NoHayMercados"))
        }
    }, [state.mercadosProveedor, state.idioma, actions, t])



    useEffect(() => {
        loadLineasPedidosIniciales()
    }, [estado.mercado, estado.fechaInicio, estado.FechaFin])


    //////////////////////////////////
    /// Filtrar Desde-Hasta Fechas ///
    //////////////////////////////////
    function actualizadorFechaDesde(fecha) {
        estado.fechaInicio = fecha
        // Fuerza Renderizado
        setEstado({ ...estado })
    }


    function actualizadorFechaHasta(fecha) {
        estado.fechaFin = fecha
        // Fuerza Renderizado
        setEstado({ ...estado })
    }


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
    }


    // Contar los productos para establecer el calendario
    async function loadLineasPedidosIniciales() {
        // Vacia lo que podríamos tener previamente
        if (estado.lineasPedidos.length > 0) {
            setEstado({ ...estado, lineasPedidos: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        let mercadosFiltro
        if (estado.mercado !== undefined && state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod
            await listaVentasClientesProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", mercado: mercadosFiltro, diaDesde: FechaAaaaMmDd(estado.fechaInicio), diaHasta: FechaAaaaMmDd(estado.fechaFin), idioma: state.idioma }).then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    preparaVisExcel(data)
                }
            })
        }
    }


    function preparaVisExcel(data) {
        if (data.length > 0) {
            data = OrdenaArray2Campos(data, "PedMer", "PedRaz")
            //console.log('data = ', data)

            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            //
            let mercadoCod = ""
            let clienteRaz = ""
            let numPedidos = 0, impPedidos = 0
            let cabeceraExcel = [
                t("MisInformes.Mercado"),
                t("MisInformes.Cliente"),
                t("MisInformes.Fecha1"),
                t("MisInformes.NumeroLineas"),
                t("MisInformes.NumeroPedidos"),
                t("MisInformes.TotalPedidos"),
                t("MisInformes.MediaPedido")
            ]

            let nExc = 0
            let nRes = 0
            for (let i = 0; i < data.length; i++) {
                // Totales. //
                numPedidos += 1
                impPedidos += data[i].importeLinea
                // Si cambia de mercado + Cliente. //
                if (mercadoCod + clienteRaz !== data[i].PedMer + data[i].PedRaz) {
                    resultado.push({
                        Mercado: state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE,
                        Cliente: data[i].PedRaz,
                        Fecha: FechaDdMmAAAA(new Date(data[i].Fecha)),
                        Lineas: data[i].numeroLineas,
                        NumeroPedidos: 1,
                        Importe: data[i].importeLinea,
                    })
                    //
                    resultadoExcel.push({})
                    nExc = resultadoExcel.length - 1
                    //
                    resultadoExcel[nExc][cabeceraExcel[0]] = state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE
                    resultadoExcel[nExc][cabeceraExcel[1]] = data[i].PedRaz
                    resultadoExcel[nExc][cabeceraExcel[2]] = FechaDdMmAAAA(new Date(data[i].Fecha))
                    resultadoExcel[nExc][cabeceraExcel[3]] = data[i].numeroLineas
                    resultadoExcel[nExc][cabeceraExcel[4]] = 1
                    resultadoExcel[nExc][cabeceraExcel[5]] = data[i].importeLinea
                    resultadoExcel[nExc][cabeceraExcel[6]] = data[i].importeLinea
                    //  
                    mercadoCod = data[i].PedMer
                    clienteRaz = data[i].PedRaz
                } else {
                    nRes = resultado.length - 1
                    resultado[nRes].Lineas += data[i].numeroLineas
                    resultado[nRes].NumeroPedidos += 1
                    resultado[nRes].Importe += data[i].importeLinea
                    //
                    nExc = resultadoExcel.length - 1
                    resultadoExcel[nExc][cabeceraExcel[3]] += data[i].numeroLineas
                    resultadoExcel[nExc][cabeceraExcel[4]] += 1
                    resultadoExcel[nExc][cabeceraExcel[5]] += data[i].importeLinea
                    resultadoExcel[nExc][cabeceraExcel[6]] = resultado[nRes].Importe / resultado[nRes].NumeroPedidos
                }
                //
                if (!state.esProductor) {
                    /// Ni está hecho por ahora
                    ///
                    ///
                }
            }
            // Después de Acumular, Redondemos los Totales. //
            resultadoExcel.forEach(linea => {
                linea[cabeceraExcel[5]] = RedondeaNumero(linea[cabeceraExcel[5]], 2)
                linea[cabeceraExcel[6]] = RedondeaNumero(linea[cabeceraExcel[6]], 2)
            })

            // Añade los sumatorios finales
            resultado.push({ Mercado: "Totales", Cliente: "", Lineas: "", NumeroPedidos: numPedidos, Importe: impPedidos })
            //
            estado.lineasPedidos = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }


    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisInformes.VentasClientes")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 2 / 3, display: 'flex', /*justifyContent: 'center'*/ }}>
                    <Box>
                        <BotonFecha
                            //margenI={5}
                            nombre={t("MisInformes.Desde")}
                            fecha={FechaDdMmAAAA(estado.fechaInicio)}
                            actualizadorFecha={actualizadorFechaDesde}
                        />
                    </Box>
                    <Box>
                        <BotonFecha
                            margenI={5}
                            nombre={t("MisInformes.Hasta")}
                            fecha={FechaDdMmAAAA(estado.fechaFin)}
                            actualizadorFecha={actualizadorFechaHasta}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                </Box>
                <Box sx={{ ml: 3 }}>
                    <BotonXlsx data={estado.lineasExcel} filename={t("MisInformes.VentasClientes")} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    {estado.mercado !== undefined &&
                        <Lista
                            id={"Mer"}
                            elementos={estado.seleccionMercados}
                            accion={handleFiltroMercado}
                            activos={estado.mercado}
                        />
                    }
                </Box>
            </Box>
        </Fragment>
    )


    const listaLineasListado = (
        estado.lineasPedidos.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Mercado")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Cliente")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Fecha1")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.NumeroLineas")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.NumeroPedidos")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.TotalPedidos")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.MediaPedido")}</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {estado.lineasPedidos.map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell><Typography variant={estado.lineasPedidos.length - 1 === i ? "azul15" : "gris13"}> {l.Mercado}</Typography></TableCell>
                                    <TableCell>{l.Cliente}</TableCell>
                                    <TableCell>{l.Fecha}</TableCell>
                                    <TableCell align="right">{l.Lineas}</TableCell>
                                    <TableCell align="right"><Typography variant={estado.lineasPedidos.length - 1 === i ? "azul15" : "gris13"}> {l.NumeroPedidos}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant={estado.lineasPedidos.length - 1 === i ? "azul15" : "gris13"}> {RedondeaPrecio(l.Importe, 2) + ' €'}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant={estado.lineasPedidos.length - 1 === i ? "azul15" : "gris13"}> {RedondeaPrecio(l.Importe / l.NumeroPedidos, 2) + ' €'}</Typography></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaLineasListado}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(InfVentasClientes)