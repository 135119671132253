import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import ThemeGeroa from './../../ThemeGeroa.js'

import { FechaDdMmAAAA, FechaAaaaMmDd, StrZero } from './../../config/funcionesPropias.js'

import BotonFecha from "./../../components/BotonFecha.js";

import { cambiarFechaEntrega, crearNuevoPedido } from '../../apis/api-pedido.js';


const DialogCambiarFecha = ({ t, ...props }) => {

    const pedido = props.p
    let f = pedido.PedFEn
    // Convertir a una fecha. //
    let a = f.substr(0, 4)
    let m = f.substr(5, 2)
    let d = f.substr(8, 2)
    const fEn = new Date(a, m - 1, d)
    const [estado, setEstado] = useState(
        {
            mensaje: "",
            fecha: fEn
        }
    )

    const handleAbandonar = () => {
        props.actualizador(false)
    }

    const handleGuardar = async () => {
        let f = estado.fecha
        // Convertir a Cadena AAAA-MM-DD //
        let fen = StrZero(f.getFullYear(), 4) + "-" +
            StrZero((f.getMonth() + 1), 2) + "-" +
            StrZero(f.getDate(), 2) + " 00:00"

        if (fen.substr(0, 10) !== pedido.PedFEn.substr(0, 10)) {
            setEstado({ ...estado, mensaje: "Registrando..." })
            let cambiar = {
                PedNum: pedido.PedNum,
                PedFEn: fen
            }
            await cambiarFechaEntrega(cambiar).then((data) => {
                if (data.error) {
                    // Se podría dar un mensaje por si falla. //
                    setEstado({ ...estado, mensaje: "Error al registrar" })
                    console.log("Error:", data)
                }
                else {
                    setEstado({ ...estado, mensaje: "" })
                    // Cerrar el Dialogo. //
                    props.actualizador(true)
                }
            })
        }
        // Salirse
    }


    const handleDuplicar = async () => {
        let f = estado.fecha
        // Convertir a Cadena AAAA-MM-DD //
        let fen = StrZero(f.getFullYear(), 4) + "-" +
            StrZero((f.getMonth() + 1), 2) + "-" +
            StrZero(f.getDate(), 2) + " 00:00"

        if (fen.substr(0, 10) !== pedido.PedFEn.substr(0, 10)) {
            setEstado({ ...estado, mensaje: "Duplicando..." })
            let hoy = new Date()
            hoy = StrZero(hoy.getFullYear(), 4) + "-" +
                StrZero((hoy.getMonth() + 1), 2) + "-" +
                StrZero(hoy.getDate(), 2) + " " +
                StrZero(hoy.getHours(), 2) + ":" +
                StrZero(hoy.getMinutes(), 2) // +":"
            // Quitar cantidades preparadas y servidas de todos los productos. //
            pedido.productos.forEach(linea => {
                linea.LinEnt = ""
                linea.LinSer = ""
                linea.LinPre = linea.LinTVa
                // Si el Producto tiene control de Existencias.
                if (linea.ProEIn > 0) {
                    linea.LinExi = Number(linea.LinCan)
                } else {
                    linea.LinExi = 0
                }
            });
            let duplicar = { ...pedido,
                PedFec: hoy,
                //PedCli: pedido.PedCli,
                //PedMer: pedido.PedMer,
                //PedFPa: pedido.PedFPa,
                PedFEn: fen,
                PedFFa: "",
                //PedREx: pedido.PedREx,
                PedREx: "1", // Siempre hay que restar
                PedPDF: "0",
                //PedTEn: pedido.PedTEn,
                //PedEma: pedido.PedEma,
                //PedRaz: pedido.PedRaz,
                //PedCal: pedido.DirCal,
                //PedPob: pedido.DirPob,
                //PedCpo: pedido.DirCpo,
                //PedPro: pedido.DirPro,
                //PedTel: pedido.CliTel,
                PedRep: "",
                PedRNo: "",
                PedREm: "",
                //productos: pedido.productos
            }
            await crearNuevoPedido(duplicar).then((data) => {
                if (data.error) {
                    // Se podría dar un mensaje por si falla. //
                    setEstado({ ...estado, mensaje: "Error al registrar" })
                    console.log("Error:", data)
                }
                else {
                    setEstado({ ...estado, mensaje: "" })
                    // Cerrar el Dialogo. //
                    props.actualizador(true)
                }
            })
        }
        // Salirse
    }


    function actualizadorFecha(fecha) {
        let fec = FechaAaaaMmDd(fecha)
        let hoy = FechaAaaaMmDd(new Date())
        if (fec < hoy) {
            setEstado({ ...estado, mensaje: "La fecha no puede ser anterior a Hoy." })
        } else {
            setEstado({ ...estado, mensaje: "", fecha: fecha })
        }
    }


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Dialog
                open={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="azul15">
                        {t("MisPedidos.CambiarFechaPedido") + " " + pedido.PedNum}
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <BotonFecha
                        nombre={t("MisPedidos.FechaEntrega")}
                        fecha={FechaDdMmAAAA(estado.fecha)}
                        actualizadorFecha={actualizadorFecha}
                    />
                    <br />
                    <Typography variant="rojo16">
                        {estado.mensaje}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ ml: 2, mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleAbandonar}
                    >
                        {t("MisPedidos.Abandonar")}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleDuplicar}
                    >
                        {t("MisPedidos.Duplicar")}
                    </Button>
                    <Button
                        disabled={pedido.PedEst === "FAC"}
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 90, height: 30 }}
                        onClick={handleGuardar}
                    >
                        {t("MisPedidos.Guardar")}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default withTranslation()(DialogCambiarFecha)