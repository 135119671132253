import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import BotonXlsx from "./../../components/BotonXlsx.js";
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { listaProductosMercadoProveedor } from './../../apis/api-producto.js'

import { PrecioConIva, PrecioConIvaNumero, RedondeaPrecio, RedondeaNumero } from "./../../config/funcionesPropias.js";


const InfProductosYMercados = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);

    // Arrays necesarios en varios submódulos
    const productosAVer = [
        t("MisInformes.Visibles"),
        t("MisInformes.Ocultos"),
        t("MisInformes.Todos")
    ]

    // Prepara las posibilidades de las capturas de Mercados
    var listaMercados = []
    for (let i = 0; i < state.mercadosProveedor.length; i++) {
        if (i === 0) {
            listaMercados.push(t("MisInformes.Todos"))
        }
        listaMercados.push(
            state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
        )
    }
    //
    const [estado, setEstado] = useState(
        {
            carga: true,
            mercado: 1,
            visibles: 0,
            seleccionMercados: listaMercados,
            lineasProductos: [],
            lineasExcel: []
        }
    )


    /////////////////////////////////////
    /// Selecciona filtro por estado. ///
    /////////////////////////////////////
    const handleFiltro = event => {
        estado.visibles = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        loadProductos()
    }


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        loadProductos()
    }


    useEffect(() => {
        if (estado.carga) {
            loadProductos()
            // Actualiza el estado sin Repintar. //
            estado.carga = false
        }
    })


    // Buscar los productos
    async function loadProductos() {
        if (estado.lineasProductos.length > 0) {
            setEstado({ ...estado, lineasProductos: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        let mercadosFiltro
        if (state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod
            await listaProductosMercadoProveedor({ esProductor: state.esProductor ? "1" : "0", proveedor: state.codProveedor, filtro: estado.visibles, mercado: mercadosFiltro, idioma: state.idioma }).then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    preparaVisualizacionYExcel(data)
                }
            })
        }
    }


    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacionYExcel(data) {
        // Reorganiza las lineas
        let resultado = []
        let resultadoExcel = []
        let mensaje = ""
        let cabeceraExcel = [
            t("MisInformes.Familia"),
            t("MisInformes.Producto"),
            t("MisInformes.Mercado"),
            t("MisInformes.Precio"),
            t("MisInformes.Factor"),
            t("MisInformes.SaleA"),
            t("MisInformes.Unidad") + '2',
            t("MisInformes.Stock")
        ]
        if (data.length > 0) {
            //console.log('data = ', data)
            //
            for (let i = 0; i < data.length; i++) {
                //
                let hayFactor = (data[i].ProFac !== 0 && data[i].ProFac !== 1)
                resultado.push({
                    Familia: state.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE,
                    Producto: (state.idioma === "Es" ? data[i].ProDesC : data[i].ProDesE) + " (" + data[i].ProCod + ")",
                    Mercado: state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE,
                    Precio: PrecioConIva(data[i].TarVal, state.gruposIva, data[i].ProIva, 2) + " €/" + state.uniAVer[data[i].ProUni],
                    Factor: hayFactor ? RedondeaPrecio(data[i].ProFac) : "",
                    SaleA: hayFactor ? PrecioConIva(data[i].TarVal / data[i].ProFac, state.gruposIva, data[i].ProIva) + " €/" + state.uniAVer[data[i].ProUn2] : "",
                    Stock: data[i].ProEIn !== "" ? RedondeaPrecio(data[i].ProExi) : ""
                })
                if (!state.esProductor) {
                    resultado[i].Productor = data[i].UsuRazonSocial
                }
                //
                resultadoExcel.push({})
                resultadoExcel[i][cabeceraExcel[0]] = state.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE
                resultadoExcel[i][cabeceraExcel[1]] = (state.idioma === "Es" ? data[i].ProDesC : data[i].ProDesE) + " (" + data[i].ProCod + ")"
                resultadoExcel[i][cabeceraExcel[2]] = state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE
                resultadoExcel[i][cabeceraExcel[3]] = PrecioConIvaNumero(data[i].TarVal, state.gruposIva, data[i].ProIva, 2)
                resultadoExcel[i][cabeceraExcel[4]] = hayFactor ? Number(data[i].ProFac) : ""
                resultadoExcel[i][cabeceraExcel[5]] = hayFactor ? PrecioConIvaNumero(data[i].TarVal / data[i].ProFac, state.gruposIva, data[i].ProIva) : ""
                resultadoExcel[i][cabeceraExcel[6]] = hayFactor ? "€ / " + state.uniAVer[data[i].ProUn2] : ""
                resultadoExcel[i][cabeceraExcel[7]] = data[i].ProEIn !== "" ? RedondeaNumero(data[i].ProExi) : ""
            }
            estado.lineasProductos = resultado
            estado.lineasExcel = resultadoExcel
            mensaje = ""
        } else {
            estado.lineasProductos = []
            estado.lineasExcel = []
            mensaje = t("MisInformes.NoHayProductos")
        }
        // Fuerza Renderizado
        setEstado({ ...estado, lineasProductos: resultado, lineasExcel: resultadoExcel })
        actions.cambiarMensaje(mensaje)
    }


    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 2 / 6 }}>
                    <Typography variant="verde30">
                        {t("MisInformes.ProductosYMercados")}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2, width: 4 / 6, display: 'flex' }} justifyContent="flex-end">
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    <Lista
                        id={"Mer"}
                        elementos={estado.seleccionMercados}
                        accion={handleFiltroMercado}
                        activos={estado.mercado}
                    />
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Ver")}:
                    </Typography>
                    <Lista
                        id={"Art"}
                        elementos={productosAVer}
                        accion={handleFiltro}
                        activos={estado.visibles}
                    />
                    <Box sx={{ ml: 3 }}>
                        <BotonXlsx desabilitado={estado.lineasExcel.length === 0} data={estado.lineasExcel} filename={t("MisInformes.ProductosYMercados")} />
                    </Box>
                </Box>
            </Box>
        </Fragment>
    )


    const listaProductos = (
        estado.lineasProductos.length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                {!state.esProductor && <TableCell sx={{ width: 200 }} ><Typography variant="azul15">{t("MisInformes.Productor")}</Typography></TableCell>}
                                <TableCell sx={{ width: 200 }} ><Typography variant="azul15">{t("MisInformes.Familia")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Producto")}</Typography></TableCell>
                                <TableCell sx={{ width: 200 }} ><Typography variant="azul15">{t("MisInformes.Mercado")}</Typography></TableCell>
                                <TableCell align="right" sx={{ width: 80 }}><Typography variant="azul15">{t("MisInformes.Precio")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.Factor")}</Typography></TableCell>
                                <TableCell align="right" sx={{ width: 80 }}><Typography variant="azul15">{t("MisInformes.SaleA")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.Stock")}</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {estado.lineasProductos.map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {!state.esProductor && <TableCell>{l.Productor}</TableCell>}
                                    <TableCell>{l.Familia}</TableCell>
                                    <TableCell>{l.Producto}</TableCell>
                                    <TableCell>{l.Mercado}</TableCell>
                                    <TableCell align="right">{l.Precio}</TableCell>
                                    <TableCell align="right">{l.Factor}</TableCell>
                                    <TableCell align="right">{l.SaleA}</TableCell>
                                    <TableCell align="right">{l.Stock}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaProductos}
                <br />
            </Container>
        </ThemeProvider>
    );
}


export default withTranslation()(InfProductosYMercados)