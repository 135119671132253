import React from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
//import Box from '@mui/material/Box';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import { alpha, styled } from '@mui/material/styles';
//import InputBase from '@mui/material/InputBase';

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
//import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'


//import CajaTexto from './../../components/CajaTexto.js';
import ThemeGeroa from './../../ThemeGeroa.js'
import { withTranslation } from 'react-i18next'




const DialogSalida = ({ t, ...props }) => {


    const handleCloseDialog = (r) => event => {
        props.actualizador(r)
    }



    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Dialog
                open={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="naranja18">{t("General.Advertencia")}</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography variant="gris13">{t("MisPedidos.TextoGuardarCambios")}</Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleCloseDialog(false)}
                    >
                        {t("General.No")}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 90, height: 30 }}
                        onClick={handleCloseDialog(true)}
                    >
                        {t("General.Si")}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
export default withTranslation()(DialogSalida)