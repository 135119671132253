import config from './../config/config.js'

const rutaApi = config.rutaApis + '/clientes'


const listaClientesMercado = (params, texto) => {
  return fetch(rutaApi + '/listaClientesMercado/' + params.mercado, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(texto)
  }).then((response) => {
    return response.json()
  }).catch((err) => { console.log(err) })
}




//////////////////////////////////////////////////////////
// Informe Clientes que han comprado en uno o varios Mercados
const listaClientesProvCoor = (params) => {
  return fetch(rutaApi + '/listaClientesProvCoor/' + params.proveedor + '/' + params.mercado, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



export {
  listaClientesMercado,
  listaClientesProvCoor
}