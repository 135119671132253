//////////////////////////////////////////////////////////////////////////////////////////////////////
// Devuelve el contador de un elemento complejo buscado en un array complejo, o -1 si no lo encuentra
//    lista: es el array complejo
//    clave: el la clave del objeto del array en el que hay que buscar
//    dato: es el dato a buscar (el primero encontrado)
//
function BuscaIndice(lista, clave, dato) {
  for (let index = 0; index < lista.length; index++) {
    if (lista[index][clave] === dato) return index;
  }
  return -1;
}
function BuscaIndiceDoble(lista, clave1, clave2, dato1, dato2) {
  for (let index = 0; index < lista.length; index++) {
    if (lista[index][clave1] + lista[index][clave2] === dato1 + dato2) return index;
  }
  return -1;
}
//
//
function BuscaTrozoIndice(lista, clave, desde, hasta, dato) {
  for (let index = 0; index < lista.length; index++) {
    if ((lista[index][clave]).substr(desde, hasta) === dato) return index;
  }
  return -1;
}


function PrecioConIva(precio, tabla, iva) {
  let porcentaje = tabla[BuscaIndice(tabla, 'IvaCod', iva)].IvaPor
  let num = precio * (1 + porcentaje / 100)
  return (num.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
}


function PrecioConIvaNumero(precio, tabla, iva) {
  let porcentaje = tabla[BuscaIndice(tabla, 'IvaCod', iva)].IvaPor
  return RedondeaNumero(precio * (1 + porcentaje / 100), 2)
}


function PrecioLineaConIva(precio, tabla, iva, cantidad) {
  let porcentaje = tabla[BuscaIndice(tabla, 'IvaCod', iva)].IvaPor
  let num = precio * cantidad * (1 + porcentaje / 100)
  return (num.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
}


function PrecioLineaConIvaNumber(precio, tabla, iva, cantidad) {
  let porcentaje = tabla[BuscaIndice(tabla, 'IvaCod', iva)].IvaPor
  return precio * cantidad * (1 + porcentaje / 100)
}


function RedondeaPrecio(precio, decimales) {
  let num = precio * 1
  if (decimales === undefined) {
    if (num % 1 !== 0) {
      decimales = 2
    }
  }
  //
  let retorno = num.toLocaleString('es-ES', { minimumFractionDigits: decimales, maximumFractionDigits: decimales })
  let longitudProblematica = decimales > 0 ? 4 + decimales + 1 : 4
  if (retorno.length === longitudProblematica) {
    retorno = retorno.substring(0, 1) + "." + retorno.substring(1)
  }
  return retorno
}

function RedondeaNumero(num, decimales = 2) {
  var signo = (num >= 0 ? 1 : -1);
  num = num * signo;
  if (decimales === 0) //con 0 decimales
      return signo * Math.round(num);
  // round(x * 10 ^ decimales)
  num = num.toString().split('e');
  num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
  // x * 10 ^ (-decimales)
  num = num.toString().split('e');
  return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
}

function FechaAaaaMmDd(d) {
  return d.getFullYear() + "-" + StrZero(d.getMonth() + 1, 2) + '-' + StrZero(d.getDate(), 2)
}


function StrZero(s, l) {
  let ret = ""
  ret = ("0000000000" + s).slice(-l)
  return ret
}


////////////////////////////////////////////////////////////////////////////////
// Devuelve la fecha que le llega conmo parámetro (fec) en formato europeo
//    largo
//    medio
//    corto
// Euskera creo que es "eu"
function FechaLargaEsp(fec, idioma) {
  if (idioma === "Es") {
    let fecha = fec.toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    return (fecha.substr(0, 1).toUpperCase() + fecha.substr(1))
  }
  else {
    let f = fec;
    let dias = ["Igande", "Astelehen", "Astearte", "Asteazken", "Ostegun", "Ostiral", "Larunbat"];
    let meses = ["urtarril", "otsail", "martxo", "apiril", "maiatz", "ekain", "uztail", "abuztu", "irail", "urri", "azaro", "abendu"];
    // cadena con el año aaaa
    let ano = "" + f.getFullYear();
    // ultimo dígito 0123
    let dig = ano.substr(3);
    // terminación del año
    let terAno = "ko";
    // si el año termina en consonante
    // x1 (bat, hogeita bat) excepto 11 (hamaika)
    // y derivados de 11: 31,51,71,91 (xx hogeita hamaika)
    // x5 (bost)
    if (dig === "1" || dig === "5") {
      terAno = "eko";
    }
    // dia del mes
    let dia = f.getDate()
    // terminación del día
    let terDia = "a";
    // día 11 (amaika) o 31 (hogeita amaika)
    if (dia === 11 || dia === 31) {
      terDia = "";
    }
    return (dias[f.getDay()] + "a, " + ano + terAno + " " + meses[f.getMonth()] + "aren " + dia + terDia);

    //return(fec.toLocaleDateString('eu-EU', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))

    //return(fec.toLocaleDateString('fa-IR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))
  }
}



function FechaDdMmAAAA(fec, inc) {
  fec = fec === undefined ? new Date() : fec
  inc = inc === undefined ? 0 : inc * 1000 * 24 * 60 * 60

  fec = new Date(fec.getTime() + inc)
  return StrZero(fec.getDate(), 2) + '-' + StrZero((fec.getMonth() + 1), 2) + '-' + StrZero(fec.getFullYear(), 4)
}



function FechaAaaaMmDdMmSs(fec, inc) {
  fec = fec === undefined ? new Date() : fec
  inc = inc === undefined ? 0 : inc * 1000 * 24 * 60 * 60

  fec = new Date(fec.getTime() + inc)
  return StrZero(fec.getFullYear(), 4) + '-' + StrZero((fec.getMonth() + 1), 2) + '-' + StrZero(fec.getDate(), 2) + ' ' + StrZero(fec.getHours(), 2) + ':' + StrZero(fec.getMinutes(), 2)
}





function AaaaMmDd_a_Fecha(f) {
  // La Fecha llega en formato aaaa-mm-dd. //
  let aFecha = f.split("-");
  // Convertir a una Fecha. //
  return new Date(aFecha[0], aFecha[1]-1, aFecha[2])
}



//////////////////////////////////////////////////////////////////
// Devuelve un array complejo ordenado por un campo
//    lista: es el array complejo
//    campo: es el campo del elemento del array por el que hay que ordenar
//
function OrdenaArray(lista, campo) {
  return (lista.sort(function (a, b) {
    if (a[campo] > b[campo]) {
      return 1
    }
    if (a[campo] < b[campo]) {
      return -1
    }
    return 0
  }))
}

function OrdenaArrayInverso(lista, campo) {
  return (lista.sort(function (a, b) {
    if (a[campo] < b[campo]) {
      return 1
    }
    if (a[campo] > b[campo]) {
      return -1
    }
    return 0
  }))
}

function OrdenaArray2Campos(lista, campo1, campo2) {
  return (lista.sort(function (a, b) {
    if (a[campo1] + a[campo2] > b[campo1] + b[campo2]) {
      return 1
    }
    if (a[campo1] + a[campo2] < b[campo1] + b[campo2]) {
      return -1
    }
    return 0
  }))
}



function OrdenaArray3Campos(lista, campo1, campo2, campo3) {
  return (lista.sort(function (a, b) {
    if (a[campo1] + a[campo2] + a[campo3] > b[campo1] + b[campo2] + b[campo3]) {
      return 1
    }
    if (a[campo1] + a[campo2] + a[campo3] < b[campo1] + b[campo2] + b[campo3]) {
      return -1
    }
    return 0
  }))
}



function OrdenaArray4Campos(lista, campo1, campo2, campo3, campo4) {
  return (lista.sort(function (a, b) {
    if (a[campo1] + a[campo2] + a[campo3] + a[campo4] > b[campo1] + b[campo2] + b[campo3 + b[campo4]]) {
      return 1
    }
    if (a[campo1] + a[campo2] + a[campo3] + a[campo4] < b[campo1] + b[campo2] + b[campo3] + b[campo4]) {
      return -1
    }
    return 0
  }))
}



//////////////////////////////////////////////////////
// Datos de correos con permisos para todo en el mapa
let usuariosEspeciales = [
  "armentia@geroa.com",
  "soporte@geroa.com",
  "sustatu@ekolurra.eus",
  "eneek.info@gmail.com"
]
function EsUsuarioEspecial(mail) {
  return usuariosEspeciales.indexOf(mail)
}




export {
  BuscaIndice,
  BuscaIndiceDoble,
  BuscaTrozoIndice,
  RedondeaPrecio,
  RedondeaNumero,
  PrecioConIva,
  PrecioConIvaNumero,
  PrecioLineaConIva,
  PrecioLineaConIvaNumber,
  FechaAaaaMmDd,
  FechaAaaaMmDdMmSs,
  StrZero,
  FechaLargaEsp,
  FechaDdMmAAAA,
  AaaaMmDd_a_Fecha,
  OrdenaArray,
  OrdenaArray2Campos,
  OrdenaArray3Campos,
  OrdenaArray4Campos,
  OrdenaArrayInverso,
  EsUsuarioEspecial
}

